import PropTypes from 'prop-types'
import React, { Component } from 'react'

const wrapperStyle = {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'true',
    height: '100%',
    justifyContent: 'center',
    overflow: 'hidden',
    zIndex: -1,
}

const centerVideoStyle = {
    objectFit: 'fill',
    pointerEvents: 'none',
    userSelect: 'none',
}

const getVideoStyle = (innerRatio: number, outerRatio: number): object => innerRatio < outerRatio ? {
    width: '100%',
    height: 'auto',
    ...centerVideoStyle,
} : {
    width: 'auto',
    height: '100%',
    ...centerVideoStyle,
}


export interface BackgroundProps {
    className: string
    onStart: () => void
    open?: boolean
    poster: string
    resizable?: boolean
    src: string
    useViewport?: boolean
}

export interface BackgroundState {
    sourceAspectRatio: number
    viewportAspectRatio: number
}

export class Background extends Component<BackgroundProps, BackgroundState> {

    static propTypes = {
        className: PropTypes.string,
        onStart: PropTypes.func,
        open: PropTypes.bool,
        poster: PropTypes.string.isRequired,
        resizable: PropTypes.bool.isRequired,
        src: PropTypes.string.isRequired,
        useViewport: PropTypes.bool,
    }

    static defaultProps = {
        onStart: () => {},
        open: true,
        resizable: false,
        poster: '/asset/bg-underwater-1080p.webp',
        src: '/asset/bg-underwater-1080p.mp4',
        useViewport: true,
    }

    public containerRef: any

    constructor(props: BackgroundProps) {
        super(props)

        this.state = {
            sourceAspectRatio: -1,
            viewportAspectRatio: -1,
        }

        this.start = this.start.bind(this)
        this.updateSourceAspectRatio = this.updateSourceAspectRatio.bind(this)
        this.updateViewportAspectRatio = this.updateViewportAspectRatio.bind(this)
    }

    componentDidMount() {
        const { resizable } = this.props

        this.updateViewportAspectRatio()

        if (resizable) {
            window.addEventListener('resize', this.updateViewportAspectRatio)
        }
    }

    componentWillUnmount() {
        const { resizable } = this.props

        if (resizable) {
            window.removeEventListener('resize', this.updateViewportAspectRatio)
        }
    }

    render() {
        const { className, open, poster, src } = this.props

        if (! open) {
            return null
        }

        const { sourceAspectRatio, viewportAspectRatio } = this.state
        const videoStyle = getVideoStyle(sourceAspectRatio, viewportAspectRatio)

        return (
            <div
                className={className}
                ref={ ref => { this.containerRef = ref } }
                style={wrapperStyle as any}
            >
                <video
                    autoPlay
                    loop
                    muted
                    playsInline
                    poster={poster}
                    src={src}
                    style={videoStyle}
                    onLoadedMetadata={this.updateSourceAspectRatio}
                    onCanPlayThrough={this.start}
                >
                    {'Your browser does not support video. Please upgrade.'}
                </video>
            </div>
        )
    }

    start(e: any) {
        const { onStart } = this.props

        e.target.play()

        if (onStart) {
            onStart()
        }
    }

    updateSourceAspectRatio(e: any) {
        const { videoWidth, videoHeight } = e.target

        this.setState({
            sourceAspectRatio: videoWidth / videoHeight,
        })
    }

    updateViewportAspectRatio() {
        if (! this.containerRef) {
            return
        }

        const box = this.getBoundingClientRect()

        if (! box) {
            return
        }

        const { width, height } = box

        this.setState({
            viewportAspectRatio: width / height,
        })
    }

    getBoundingClientRect() {
        const { useViewport } = this.props

        if (useViewport) {
            if (typeof window !== 'undefined') {
                return {
                    height: window.innerHeight,
                    width: window.innerWidth,
                }
            }
        } else {
            if (this.containerRef) {
                this.containerRef.getBoundingClientRect()
            }
        }

        return null
    }
}
