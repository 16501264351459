import PropTypes from 'prop-types'
import React from 'react'
import markerIconPng from 'leaflet/dist/images/marker-icon.png'
import { Icon } from 'leaflet'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import { styled, Typography } from '@mui/material'
import { OPEN_IN_MAPS, useConfig, useLogEvent, useTranslate } from '../service'

const PREFIX = 'OrMap'

const classes = {
    root: `${PREFIX}-root`,
}

const StyledMap = styled('div')({
    [`&.${classes.root}`]: {
        borderTop: '1px solid rgb(221, 221, 221)',
        margin: 0,
        width: '100%',
        height: 250,
        zIndex: 1,
    },
    [`& .leaflet-container`]: {
        width: '100%',
        height: 250,
    },
    [`& .leaflet-marker-pane img`]: {
        marginLeft: '-25px',
        marginTop: '-41px',
    },
})

export interface MapProps {
    attribution: string
    lat: number
    lng: number
    maxZoom: number
    scrollWheelZoom: boolean
    tileLayer: string
    zoom: number
}

export const Map = (props: MapProps) => {
    const {
        attribution,
        lat,
        lng,
        maxZoom,
        scrollWheelZoom,
        tileLayer,
        zoom,
    } = props

    const logEvent = useLogEvent()
    const translate = useTranslate()
    const { location, stadiaMapsApiKey } = useConfig()
    const { address, locality, postalCode, province, url } = location

    const handleOpenInMaps = () => {
        logEvent(OPEN_IN_MAPS, {
            place: 'orider',
            where: 'map',
        })
    }

    return (
        <StyledMap className={classes.root}>
            <MapContainer
                center={[lat, lng]}
                scrollWheelZoom={scrollWheelZoom}
                zoom={zoom}
            >
                <TileLayer
                    attribution={attribution}
                    maxZoom={maxZoom}
                    url={`${tileLayer}?api_key=${stadiaMapsApiKey}`}
                />
                <Marker
                    icon={new Icon({
                        iconUrl: markerIconPng,
                    })}
                    position={[lat, lng]}
                >
                    <Popup>
                        <Typography
                            component='p'
                            variant='highlight2'
                        >
                            {translate(`product.stop.ocean-rider`)}
                        </Typography>
                        <Typography
                            component='p'
                            variant='body2'
                        >
                            {address}
                            <br/>
                            {postalCode}
                            {' '}
                            {locality}
                            <br/>
                            {province}
                            <br/>
                        </Typography>
                        <a
                            rel='noopener noreferrer'
                            target='_blank'
                            title={translate('app.links.whereWeAre')}
                            href={url}
                            onClick={handleOpenInMaps}
                        >
                            {translate('app.links.whereWeAre')}
                        </a>
                    </Popup>
                </Marker>
            </MapContainer>
        </StyledMap>
    )
}

Map.propTypes = {
    attribution: PropTypes.string,
    lat: PropTypes.number,
    lng: PropTypes.number,
    maxZoom: PropTypes.number,
    scrollWheelZoom: PropTypes.bool,
    tileLayer: PropTypes.string,
    zoom: PropTypes.number,
}

// See https://github.com/leaflet-extras/leaflet-providers
Map.defaultProps = {
    attribution: '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors',
    lat: 28.40071,
    lng: -16.32352,
    maxZoom: 20,
    scrollWheelZoom: false,
    tileLayer: 'https://tiles.stadiamaps.com/tiles/outdoors/{z}/{x}/{y}{r}.png',
    zoom: 9,
}
