import React from 'react'
import { Footer } from './Footer'
import { Header } from './Header'
import { Map } from './Map'
import { Outlet } from 'react-router-dom'
import { SkipToContent } from './SkipToContent'
import { useProduct } from '../service'

export const Layout = () => {
    const product = useProduct()

    return (
        <>
            <SkipToContent />
            <div>
                <Header />
                <main id='main'>
                    <Outlet />
                </main>
                {
                    product ? null : (<Map />)
                }
                <Footer />
            </div>
        </>
    )
}
