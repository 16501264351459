import { ConfigContext } from './ConfigContext'
import { ReactNode } from 'react'
import { config } from './config'

export interface ConfigContextProviderProps {
    children: ReactNode
}

export const ConfigContextProvider = (props: ConfigContextProviderProps) => {
    const { children } = props

    return (
        <ConfigContext.Provider value={config}>
            {children}
        </ConfigContext.Provider>
    )
}
