import LaunchIcon from '@mui/icons-material/Launch'
import PropTypes from 'prop-types'
import React from 'react'
import { BOOK, ProductConfig, useTranslate, useLogEvent } from '../../service'
import { Spacer } from '../Spacer'
import { Typography, styled } from '@mui/material'

const BoldSpan = styled('span')({
    fontSize: '30px',
    fontWeight: 800,
})

const RedSpan = styled('span')({
    color: 'rgb(208,22,23)',
    fontSize: '32px',
    fontWeight: 800,
})

const TicketAnchor = styled('a')({
    color: 'rgb(34,34,34)',
    textDecoration: 'underline',
})

export interface ProductTicketProps {
    product: ProductConfig
    ticketUrl?: string
}

export const ProductTicket = (props: ProductTicketProps) => {
    const { product, ticketUrl } = props
    const { id } = product

    const logEvent = useLogEvent()
    const translate = useTranslate()

    const handleBook = () => {
        logEvent(BOOK, {
            channel: 'bonosvip',
            product: id,
            where: 'detail',
        })
    }

    if (! ticketUrl) {
        return null
    }

    return (
        <>
            <Spacer />
            <div>
                <Typography component='h2'>
                    <BoldSpan>bonos</BoldSpan>
                    <RedSpan>vip</RedSpan>
                </Typography>
                <Typography variant='body2'>
                    {
                        translate(`product.booking.ticket`)
                    }
                </Typography>
                <TicketAnchor
                    rel='noopener noreferrer'
                    href={ticketUrl}
                    onClick={handleBook}
                    target='_blank'
                >
                    <Typography variant='highlight2'>
                        {translate('product.booking.ticketUrl')}
                        <LaunchIcon
                            fontSize='small'
                            sx={{ fontSize: '16px', marginLeft: '8px' }}
                        />
                    </Typography>
                </TicketAnchor>
            </div>
        </>
    )
}

ProductTicket.propTypes = {
    product: PropTypes.object,
}
