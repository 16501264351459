import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import React from 'react'
import clsx from 'clsx'
import { Anchor } from './Anchor'
import { Container, Stack, Typography, styled, useTheme, useMediaQuery } from '@mui/material'
import { SkiJetIcon } from './icon'
import { Link } from './Link'
import { LocaleSwitcher } from './LocaleSwitcher'
import { Spacer } from './Spacer'
import {
    CONTACT_BY_EMAIL,
    CONTACT_BY_PHONE,
    OPEN_IN_MAPS, OPEN_SOCIAL,
    useConfig,
    useLogEvent,
    useProduct,
    useTranslate
} from '../service'

const PREFIX = 'OrFooter'

const classes = {
    root: `${PREFIX}-root`,
    row: `${PREFIX}-row`,
    col: `${PREFIX}-col`,
    col2: `${PREFIX}-col2`,
    col3: `${PREFIX}-col3`,
    link: `${PREFIX}-link`,
    social: `${PREFIX}-social`,
    copy: `${PREFIX}-copy`,
    skiJet: `${PREFIX}-skijet`,
    mb: `${PREFIX}-mb`,
}

const StyledFooter = styled('footer')(({ theme }) => ({
    [`&.${classes.root}`]: {
        backgroundColor: 'rgb(247, 247, 247)',
        borderTop: '1px solid rgb(221, 221, 221)',
        padding: theme.spacing(5, 0),
    },
    [`&.${classes.mb}`]: {
        paddingBottom: theme.spacing(16),
    },
    [`& .${classes.row}`]: {
        alignItems: 'flex-start',
        display: 'flex',
        gap: theme.spacing(2),
        justifyContent: 'flex-start',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },
    },
    [`& .${classes.col}`]: {
        display: 'flex',
        flex: 1,
        flexBasis: '100%',
        flexDirection: 'column',
    },
    [`& .${classes.col2}`]: {
        width: '50%',
    },
    [`& .${classes.col3}`]: {
        width: '33%',
        [theme.breakpoints.down('md')]: {
            marginLeft: 0,
            width: '100%',
        },
    },
    [`& .${classes.link}`]: {
        marginBottom: 0,
    },
    [`& .${classes.social}`]: {
        color: 'rgb(113,113,113)',
        display: 'flex',
        marginTop: '-2px',
        marginLeft: theme.spacing(2),
        [`&:hover`]: {
            color: 'rgb(34,34,34)',
        },
    },
    [`& .${classes.copy}`]: {
        alignItems: 'center',
        display: 'flex',
        flexGrow: 1,
        margin: 0,
    },
    [`& .${classes.skiJet}`]: {
        color: '#999',
        display: 'flex',
        marginRight: theme.spacing(2),
    },
}))

export const Footer = () => {
    const logEvent = useLogEvent()
    const translate = useTranslate()
    const theme = useTheme()
    const isSmall = useMediaQuery(theme.breakpoints.down('md'))
    const product = useProduct()
    const { contact, location } = useConfig()
    const { address, locality, postalCode, province, url } = location
    const { facebook, instagram, emailAddress, phone } = contact

    const mailTo = `mailto:${emailAddress}`
    const tel = `tel:${phone.replace(/\D/g, '')}`

    const handleContactByEmail = (): void => {
        logEvent(CONTACT_BY_EMAIL, {
            where: 'banner',
        })
    }

    const handleContactByPhone = () => {
        logEvent(CONTACT_BY_PHONE, {
            where: 'footer',
        })
    }

    const handleOpenInMaps = () => {
        logEvent(OPEN_IN_MAPS, {
            place: 'orider',
            where: 'footer',
        })
    }

    const handleSocial = (network: string) =>
        () => {
            logEvent(OPEN_SOCIAL, {
                network,
            })
        }

    return (
        <StyledFooter className={clsx(classes.root, {
            [classes.mb]: !! product,
        })}>
            <Container maxWidth='md'>
                <Stack
                    alignItems='flex-start'
                    className={classes.row}
                    direction='row'
                    justifyContent='center'
                    spacing={2}
                >
                    <Stack className={clsx(classes.col, classes.col3)} direction='column'>
                        <Typography component='h3' variant='h3' gutterBottom>
                            {translate('app.title.info')}
                        </Typography>
                        <Link to='/about-us'>
                            <Typography
                                className={classes.link}
                                variant='body2'
                            >
                                {translate('app.links.aboutUs')}
                            </Typography>
                        </Link>
                        {/*
                        <Link to='/faq'>
                            <Typography
                                className={classes.link}
                                variant='body2'
                            >
                                {translate('app.links.faq')}
                            </Typography>
                        </Link>
                        */}
                        <Link to='/cookie-policy'>
                            <Typography
                                className={classes.link}
                                variant='body2'
                            >
                                {translate('app.links.cookies')}
                            </Typography>
                        </Link>
                        <Link to='/privacy-policy'>
                            <Typography
                                className={classes.link}
                                variant='body2'
                            >
                                {translate('app.links.privacy')}
                            </Typography>
                        </Link>
                        <Link to='/terms-and-conditions'>
                            <Typography
                                className={classes.link}
                                variant='body2'
                            >
                                {translate('app.links.terms')}
                            </Typography>
                        </Link>
                    </Stack>
                    <Stack className={clsx(classes.col, classes.col3)} direction='column'>
                        <Typography component='h3' variant='h3' gutterBottom>
                            {translate('app.title.location')}
                        </Typography>
                        <Anchor
                            onClick={handleOpenInMaps}
                            title={translate('app.links.whereWeAre')}
                            href={url}
                        >
                            <Typography variant='body2'>
                                {address}
                                <br/>
                                {postalCode}
                                {' '}
                                {locality}
                                <br/>
                                {province}
                                <br/>
                            </Typography>
                        </Anchor>
                    </Stack>
                    <Stack className={clsx(classes.col, classes.col3)} direction='column'>
                        <Typography component='h3' variant='h3' gutterBottom>
                            {translate('app.title.contactUs')}
                        </Typography>
                        <Anchor
                            href={tel}
                            onClick={handleContactByPhone}
                            title={translate('app.links.phone')}
                        >
                            <Typography variant='body2'>
                                {contact.phone}
                            </Typography>
                        </Anchor>
                        <Anchor
                            href={mailTo}
                            onClick={handleContactByEmail}
                            title={translate('app.links.email')}
                        >
                            <Typography variant='body2'>
                                {contact.emailAddress}
                            </Typography>
                        </Anchor>
                    </Stack>
                </Stack>
                <Spacer />
                <Stack
                    alignItems='center'
                    direction='row'
                    justifyContent='flex-start'
                    spacing={2}
                >
                    <Typography
                        className={classes.copy}
                        variant='body2'
                    >
                        <SkiJetIcon className={classes.skiJet} />
                        {`© ${new Date().getFullYear()} Ocean Rider Tenerife`}
                    </Typography>
                    {
                        isSmall ? null : (
                            <LocaleSwitcher 
                                displayLabel 
                                selectOnClick
                            />
                        )
                    }
                    {
                        instagram ? (
                            <Anchor
                                className={classes.social}
                                href={instagram}
                                onClick={handleSocial('instagram')}
                                title='Instagram'
                            >
                                <InstagramIcon sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />
                            </Anchor>
                        ) : null
                    }
                    {
                        facebook ? (
                            <Anchor
                                className={classes.social}
                                href={facebook}
                                onClick={handleSocial('facebook')}
                                title='Facebook'
                            >
                                <FacebookIcon sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />
                            </Anchor>
                        ) : null
                    }
                </Stack>
            </Container>
        </StyledFooter>
    )
}
