import * as cookie from 'cookie'
import { documentHasCookies, parseCookies, readCookie } from './util'
import {
    Cookie,
    CookieChanged,
    CookieChangeListener,
    CookieGetOptions,
    CookieParseOptions,
    CookieSetOptions
} from './types'

export default class Cookies {
    private cookies: { [name: string]: Cookie }
    private listeners: CookieChangeListener[] = []
    private documentHasCookies: boolean = false

    constructor(cookies?: string | object | null, options?: CookieParseOptions) {
        this.cookies = parseCookies(cookies, options)

        new Promise(() => {
            this.documentHasCookies = documentHasCookies()
        }).catch(() => {})
    }


    public addListener(listener: CookieChangeListener): void {
        this.listeners.push(listener)
    }

    public removeListener(listener: CookieChangeListener): void {
        const i = this.listeners.indexOf(listener)

        if (i >= 0) {
            this.listeners.splice(i, 1)
        }
    }


    public get enabled(): boolean {
        return this.documentHasCookies
    }


    public all(options: CookieGetOptions = {}, parseOptions: CookieParseOptions) {
        this.update(parseOptions)
        const result: { [name: string]: any } = {}

        for (let name in this.cookies) {
            result[name] = readCookie(this.cookies[name], options)
        }

        return result
    }

    public get(name: string, options?: CookieGetOptions): any
    public get<T>(name: string, options?: CookieGetOptions): T
    public get(name: string, options: CookieGetOptions = {}, parseOptions?: CookieParseOptions) {
        this.update(parseOptions)

        return readCookie(this.cookies[name], options)
    }

    public set(name: string, value: Cookie, options?: CookieSetOptions): void {
        if (typeof value === 'object') {
            value = JSON.stringify(value)
        }

        this.cookies = { ...this.cookies, [name]: value }

        if (this.documentHasCookies) {
            document.cookie = cookie.serialize(name, value, options)
        }

        this.emit({ name, value, options })
    }

    public remove(name: string, options?: CookieSetOptions): void {
        const finalOptions = {
            ...options,
            expires: new Date(1970, 1, 1, 0, 0, 1),
            maxAge: 0,
        }

        this.cookies = { ...this.cookies }
        delete this.cookies[name]

        if (this.documentHasCookies) {
            document.cookie = cookie.serialize(name, '', finalOptions)
        }

        this.emit({ name, value: undefined, options: finalOptions })
    }

    public removeAll(options?: CookieSetOptions): void {
        for (let name in this.cookies) {
            this.remove(name, options)
        }
    }


    private emit(props: CookieChanged): void {
        for (const listener of this.listeners) {
            listener(props)
        }
    }

    private update(parseOptions?: CookieParseOptions): void {
        if (this.documentHasCookies) {
            this.cookies = parseCookies(document.cookie, parseOptions)
        }
    }
}
