import React from 'react'
import { Divider, DividerProps, styled } from '@mui/material'

const PREFIX = 'OrSpacer'

const classes = {
    root: `${PREFIX}-root`,
}

const StyledDivider = styled(Divider)(({ theme }) => ({
    [`&.${classes.root}`]: {
        margin: theme.spacing(3, 0),
    },
}))

export const Spacer = (props: DividerProps) => (
    <StyledDivider className={classes.root} {...props} />
)
