import { Locale } from '../types'

const en: Locale = {
    name: 'English',
    messages: {
        app: {
            title: {
                close: 'Close',
                contactUs: 'Contact Us',
                data: 'Company data',
                info: 'Ocean Rider',
                location: 'Where we are',
                chooseLanguage: 'Choose language',
                selectDuration: 'Select duration',
                social: 'Social Networks',
            },
            links: {
                aboutUs: 'About Us',
                contactUs: 'Contact Us',
                cookies: 'Cookie Policy',
                email: 'Contact Us',
                faq: 'FAQs',
                home: 'Home',
                terms: 'Terms and Conditions',
                openInMaps: 'Open in maps',
                phone: 'Contact Us',
                privacy: 'Privacy Policy',
                skipToContent: 'Skip to content',
                switchLocale: 'Switch locale',
                whereWeAre: 'Where we are',
            },
        },
        brand: {
            hash: '#weridetheocean',
            motto: 'Jet Ski Rental in Tenerife',
            name: 'Ocean Rider',
            schedule: '10:00-20:00 h',
            workdays: 'Open all days',
        },
        company: {
            label: {
                id: 'NIF',
                registry: 'Número de registro mercantil',
            },
        },
        cookies: {
            consent: {
                accept: 'Accept',
                accepted: 'You accepted cookies in this browser. If you want to opt out, you can do so by pressing the button below:',
                decline: 'Decline',
                declined: 'You declined cookies in this browser.',
                message: 'We use cookies to improve your experience on our website.',
                optOut: 'I want to opt out',
                undefined: 'You have not specified your preferences regarding the use of cookies in this browser.',
            },
            desc: {
                consent: 'Indica si el usuario ha dado su consentimiento para el uso de cookies',
                day: '1 day',
                days: '%{days} days',
                description: 'Description',
                expiry: 'Expiry',
                ga: 'Used to identify users',
                gat: 'Used to identify users',
                gid: 'Used to limit the request rates. When implementing Google Analytics using Google Tag Manager, this cookie is named "_dc_gtm_".',
                locale: 'Used to store your language preferences',
                minute: '1 minute',
                provider: 'Provider',
                years: '%{years} years',
            },
            text: {
                aepd: 'in this link',
                howto: 'To manage your cookie options, refer to your browser documentation:',
                manage: 'You may allow, block or remove cookies installed in your devices using your browser settings. Also, you can configure your browser to accept, reject or ask whether cookies can be stored on your device.',
                more: 'You have more information regarding privacy settings available in the Agencia Española de Protección de Datos website, ',
                performance: 'These cookies are used to gather statistical information about the use of our website, also called analytics cookies. We use this data for performance and website optimization.',
                technical: 'These cookies are used to manage your consent and store your language preferences.',
                updated: 'Last updated on July 2nd, 2023',
                what: 'Cookies and similar technologies are very small text documents or pieces of code that often contain a unique identification code. When you visit a website or use a mobile application, a computer asks your computer or mobile device for permission to save this file on your computer or mobile device and gain access to information. Information collected through cookies and similar technologies may include the date and time of the visit and how you use a particular website or mobile application.',
                why: 'We use cookies to manage your consent and ensure that we can see how our website is used and how we can improve it.',
            },
            title: {
                list: 'List of cookies',
                manage: 'Manage and disable cookies in your browser',
                performance: 'Performance cookies',
                technical: 'Technical Cookies',
                types: 'What type of cookies do we use?',
                what: 'What are cookies?',
                why: 'Why do we use cookies?',
            },
        },
        locale: {
            en: 'English',
            es: 'Español',
            it: 'Italiano',
        },
        notFound: {
            back: 'Return to the home screen',
            description: 'We can\'t find the page you\'re looking for',
            error: 'Error code: %{code}',
            title: 'Oops!',
        },
        privacy: {
            text: {
                updated: 'Last updated on July 2nd, 2023',
                bold: {
                    concerns: 'Questions or concerns?',
                    personalInfo: 'What personal information do we process?',
                    sensitiveInfo: 'Do we process any sensitive personal information?',
                    thirdPartyInfo: 'Do we receive any information from third parties?',
                    processInfo: 'How do we process your information?',
                    shareInfo: 'In what situations and with which parties do we share personal information?',
                    keepInfo: 'How do we keep your information safe?',
                    rightsInfo: 'What are your rights?',
                    exerciseRightsInfo: 'How do you exercise your rights?',
                    moreInfo: 'Want to learn more about what we do with any information we collect?',
                    short: 'In short',
                    consent: 'Consent',
                    legal: 'Obligaciones legales',
                    analytics: 'Web and Mobile Analytics',
                    vendors: 'Vendors, Consultants, and Other Third-Party Service Providers',
                    access: 'Access your personal data',
                    modification: 'Modify your personal data',
                    removal: 'Request the removal of your personal data',
                    oppose: 'Opt out of the processing of your personal data',
                    limit: 'Limit the processing of your personal data, when a condition provided by the applicable law is met',
                    portability: 'Request portability of your personal data, in the cases provided  by the applicable law',
                    automation: 'Prevent being subjected to automated decisions',
                    revoke: 'Revoke your granted consent',
                },
                data: {
                    usage: 'Log and usage data',
                    device: 'Device data',
                    location: 'Location data',
                },
                links: {
                    processInfo: 'Learn more about how we process your information',
                    keepInfo: 'Learn more about how we keep your information safe',
                    rightsInfo: 'Learn more about your privacy rights',
                },
                intro: {
                    1: 'This privacy notice for %{company} ("we," "us," or "our"), describes how and why we might collect, store, use, and/or share ("process") your information when you use our services ("services"), such as when you:',
                    2: 'Visit our website at %{url}',
                    3: 'Engage with us in other related ways',
                    4: 'Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at %{emailAddress}.',
                },
                summary: {
                    1: 'This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our table of contents below to find the section you are looking for.',
                    2: 'We do not collect personal information about you in our website.',
                    3: 'We do not process sensitive personal information.',
                    4: 'We do not receive any information from third parties.',
                    5: 'We process your information to provide, improve, and administer our Services, and to comply with law, only with your consent.',
                    6: 'We may share information in specific situations and with specific third parties.',
                    7: 'We protect the information we collect from you.',
                    8: 'You have the right to access, modify, and delete data we collect from you, opt out from and limit data processing, request data portability, prevent being subjected to automated decisions and revoke the consent you granted.',
                    9: 'The easiest way to exercise your rights is by submitting a data subject access request, or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.',
                    10: 'Review the privacy notice in full.',
                },
                collection: {
                    1: 'We do not collect personal information about you in our website.',
                    2: 'We only collet the information necessary to provide our services if you contact with us using the means provided in our website.',
                    3: 'We do not process sensitive information.',
                    4: 'Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services.',
                    5: 'We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to provide, improve and manage our Services, and for our internal analytics and reporting purposes.',
                    6: 'Like many businesses, we also collect information through cookies and similar technologies. You can find out more about this in our ',
                    7: 'The information we collect includes:',
                    8: 'Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type, and settings and information about your activity in the Services (such as the date/time stamps associated with your usage, pages and files viewed, searches, and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called "crash dumps"), and hardware settings).',
                    9: 'We collect device data such as information about your computer, phone, tablet, or other device you use to access the Services. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier, operating system, and system configuration information.',
                    10: 'We collect location data such as information about your device\'s location, which can be either precise or imprecise. How much information we collect depends on the type and settings of the device you use to access the Services. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your Location setting on your device. However, if you choose to opt out, you may not be able to use certain aspects of the Services.',
                },
                processing: {
                    1: 'We process your information to provide, improve, and administer our Services, and to comply with law.',
                },
                legalBasis: {
                    1: 'We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e., legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter into or fulfill our contractual obligations, to protect your rights, or to fulfill our legitimate business interests.',
                    2: 'The General Data Protection Regulation (GDPR) require us to explain the valid legal bases we rely on in order to process your personal information. As such, we may rely on the following legal bases to process your personal information:',
                    3: 'We may process your information if you have given us permission (i.e., consent) to use your personal information for a specific purpose. You can withdraw your consent at any time.',
                    4: 'We may process your information where we believe it necessary for compliance with our legal obligations.',
                },
                sharing: {
                    1: 'We may share information in specific situations described in this section and/or with the following third parties.',
                    2: 'We may share your data with third-party vendors, service providers, contractors, or agents ("third parties") who perform services for us or on our behalf and require access to such information to do that work. The third parties we may share personal information with are as follows:',
                },
                tracking: {
                    1: 'We may use cookies and other tracking technologies to collect and store your information.',
                    2: 'We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our ',
                },
                keeping: {
                    1: 'We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.',
                    2: 'We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements).',
                    3: 'When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.',
                },
                rights: {
                    1: 'You have the right to access and manage the information we collect from you. You can access, modify, remove at any moment.',
                    2: 'You have the following privacy rights:',
                    3: 'For instance, when they\'re no longer required for the purpose they were collected.',
                    4: 'We rely on your consent to process the data we collect from you. You can decline this consent at any time, as described in our ',
                    5: 'Also, if you consider that your rights have been violated, you have the right to make a legal claim before the competent authority (the "Agencia Española de Protección de Datos"), through its website ',
                },
                access: {
                    1: 'You can exercise your privacy rights sending a request to us, using the contact details provided in ',
                    2: 'We will consider and act on any request, according to the applicable laws.',
                },
                contact: {
                    1: 'If you have questions or comments about this notice, or you want to exercise your privacy rights, you may email us at %{emailAddress} or contact us by post at:',
                },
                updates: {
                    1: 'Yes, we will update this notice as necessary to stay compliant with relevant laws.',
                    2: 'We may update this privacy notice from time to time. The updated version will be indicated at the top of this notice. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.',
                },
            },
            title: {
                summary: 'Summary',
                toc: 'Table of contents',
                collection: 'What information do we collect?',
                processing: 'How do we process the information we collect?',
                legalBasis: 'What legal bases do we rely on to process the data we collect?',
                sharing: 'When and with whom do we share the data we collect?',
                tracking: 'Do we use cookies and other tracking technologies?',
                keeping: 'How long do we keep the information we collect?',
                rights: 'What are your privacy rights?',
                access: 'How canyou exercise your privacy rights?',
                contact: 'How can you contact us about this notice?',
                updates: 'Do we make updates to this notice?',
                personal: 'Personal information we collect',
                sensitive: 'Sensitive information',
                automatic: 'Information automatically collected',
            },
        },
        product: {
            assets: {
                assets: 'What you\'ll ride',
                customer: '%{numPassengers} passengers',
                customers: '%{minPassengers}‒%{maxPassengers} passengers',
                customerJetSki: 'You',
                customerJetSkiPlus: 'You (and your companion)',
                instructor: '1 passenger',
                instructorJetSki: 'Instructor',
            },
            booking: {
                action: 'Call %{phone} Now',
                book: 'Book',
                buy: 'Buy Online',
                priceOnline: 'Book online for %{ticketPrice} (%{ticketDiscount}% discount)',
                ticket: 'You can buy your ticket online to obtain a discount. Check the link below.',
                ticketUrl: 'Buy ticket in bonosvip',
                feeResident: '%{fee} resident',
                feeNonResident: '%{fee} non-resident',
                whatsapp: 'Chat on WhatsApp',
            },
            cancellation: {
                cancellableUntil: 'Any change, amend or cancellation will be possible within %{cancellableUntil} hours prior to the check-in date and time. If the cancellation is caused by weather conditions, the reservation will be amended or canceled free of charge.',
                notAvailable: 'This product cannot be cancelled.',
                policy: 'Cancellation Policy',
            },
            conditions: {
                age: 'Age',
                checkIn: 'Check-in',
                checkInTime: 'Check-in 30 minutes before the activity.',
                fullText: 'See full terms of service',
                harborFees: 'Will be charged %{price} by passenger and trip as harbor fees.',
                kids: 'Kids older than 8 can join the driver.',
                minAge: 'All drivers must be at least 16 years old.',
                onlyIfFavorableWeather: 'Activities will be carried out only if weather conditions allow it.',
                policy: 'Booking Rules',
                weather: 'Weather',
            },
            description: {
                'radazul-bay': 'The activity begins at the Radazul Marina and takes place in the bay of El Rosario. The covered area during the experience includes the Radazul, Tabaiba and Varadero, it will be possible to swim and take photos and videos.',
                'candelaria': 'The activity begins at Radazul Marina riding along the coast heading west. Planned stops: Barranco Hondo\'s cliffs and Candelaria. Will be possible to swim and take photos and videos.',
                'malpais-guimar': 'The activity begins at Radazul Marina riding along the coast heading west. Planned stops: Barranco Hondo\'s cliffs, Las Caletillas/Puntalarga, Candelaria, Malpaís de Güímar and Güímar harbor. Will be possible to swim and take photos and videos.',
                'punta-pachona': 'The activity begins at Radazul Marina riding along the coast heading east. Planned stops: Boca Cangrajo and Punta Pachona (Añaza). Will be possible to swim and take photos and videos.',
            },
            details: {
                approx: 'approx.',
                distance: '%{distance} km approx.',
                duration: '%{duration} minutes',
                durations: '%{durations} minutes',
                durationRange: '%{minDuration}‒%{maxDuration} minutes',
                freeRide: 'Free Ride',
                nonResident: '%{amount} Non-Resident',
                passenger: '1 passenger',
                passengers: '%{numPassengers} passengers/jet-ski',
                passengerRange: '%{minPassengers}‒%{maxPassengers} passengers',
                price: 'Since %{price}',
                purchaseOnline: 'Can be purchased online',
                route: 'Route',
                seeOnMap: 'See on maps',
                since: 'Since',
            },
            extra: {
                cancellableUntil: 'Free cancellation for %{cancellableUntil} hours',
                includesBriefing: 'Navigation briefing',
                includesInsurance: 'Includes insurance',
                includesLifejacket: 'Lifejacket',
                includesLockerRoom: 'Locker room',
                includesPhotosAndVideos: 'Photos',
                includesSwim: 'Swim',
                title: 'Included',
            },
            help: {
                distance: 'Approximate distance of the route',
                duration: 'Approximate duration of the route',
                includesBriefing: 'Includes briefing about navigation, law and jet-ski instruction',
                includesLifejacket: 'Includes lifejacket for all passengers',
                includesLockerRoom: 'Includes access to changing room/locker room with hot shower',
                includesPhotosAndVideos: 'Extra %{price} for all shots taken by trip',
                includesSwim: 'Will be possible to swim and take photos',
            },
            name: {
                'radazul-bay': 'Radazul Bay',
                'candelaria': 'Candelaria',
                'malpais-guimar': 'Malpaís de Güímar',
                'punta-pachona': 'Punta Pachona (Añaza)',
            },
            stop: {
                'boca-cangrejo': 'Boca Cangrejo',
                'candelaria': 'Candelaria',
                'malpais-guimar': 'Malpaís de Güímar',
                'ocean-rider': 'OceanRider',
                'punta-pachona': 'Punta Pachona',
                label: 'Stop',
                origin: 'Departure/Arrival',
            },
        },
        terms: {
            text: {
                updated: 'Last updated on July 2nd, 2023',
                legal: {
                    1: 'The identificative data of the owner of this website are: %{company}, with address %{address} and holder of CIF %{id}, e-mail: %{emailAddress}.',
                    2: 'Access to the website is free, with the exception of the services that are expressly indicated, which can be accessed according to the respective particular conditions, where appropriate, which establishes the prices of the product or Service, indicating whether or not applicable taxes are included and, if applicable, shipping costs.',
                    3: 'This legal information is complemented with the Privacy Policy, with the General Conditions of Use of the Website and, where appropriate, with the Particular Conditions of each Product or Service.',
                    4: '%{company} does not assume any responsibility derived from the incorrect, inappropriate or illegal use of the information provided through its page/s. Likewise, and within the legal limits, it does not assume any responsibility for the lack of accuracy, integrity or freshness of the data or informations that are published on this web site. %{company} is not responsible for the content of other Internet pages linked from the %{company} website.',
                },
                conditions: {
                    1: 'Access to this website is the sole responsibility of each User, who accepts to use the website diligently, and implies knowledge and acceptance, without exception, of the General Conditions of Use and the General Conditions of Contract contained on the website, as well as the Law, good practices and good faith, refraining from using the website in a way that prevents its normal operation, or that causes damage or harm, directly or indirectly, to the legitimate rights of %{company} or any third party. The user declares to be of legal age and have sufficient legal capacity to be liable based on these General Conditions.',
                    2: 'The user must pay the employer or his employee the price set for the contracted services without claiming exemption from payment.',
                    3: 'Access and use of certain content and services may be subject to specific conditions, legal notices, guidelines and regulations of use, which the user declares to know, and depending on the case, will replace, complement and/or modify the General Conditions stated here. Access and use of these contents and services will imply full acceptance of the Particular Conditions that regulate them, leaving these Particular Conditions automatically incorporated into these General Conditions. In case of contradiction between General and Particular Conditions, the Particular Conditions will prevail with respect to the incompatible terms and only with respect to the contents and services subject to this specific regulation.',
                },
                liability: {
                    1: 'As a User of the Service, you agree to:',
                    2: 'Use the website and all its contents for legal and non-prohibited purposes, which do not infringe current legislation or be harmful to %{company}, or to any third party;',
                    3: 'Do not disclose, disseminate or make available to third parties information, data, content, messages and, in general, any material that may be defamatory, insulting, obscene, threatening, xenophobic, incites violence, discrimination or in any way violates the morality, public order, fundamental rights, public liberties, honor, privacy, the image of third parties and, in general, current regulations;',
                    4: 'Do not disseminate, store or introduce any computer program, virus, data, code, hardware or telecommunications equipment or any other instrument or device in the website that is likely to cause damage to the website or any system, %{company} equipment, from any provider of %{company} or from any third party;',
                    5: 'Do not advertise, promote or commercial exploitation activities using the website, do not use the content of the website for advertising purposes, send messages for any commercial purpose, or to store personal data of third parties;',
                    6: 'Do not use false identities or impersonate others while using of the website;',
                    7: 'Do not to destroy, manipulate, disable or damage data, information, programs or electronic documents of %{company}, its suppliers or third parties;',
                    8: 'The %{company} website may not be rlinked to any other web page without prior authorization;',
                },
                rights: {
                    1: 'As a User of the Service, you agree to respect the industrial and intellectual property rights of %{company} and any other third party. All the contents provided on the website are subject to the industrial and/or intellectual property rights of %{company} or third-party owners thereof who have duly authorized their inclusion on the website, without being understood as assigned to the User.',
                },
                disclaimer: {
                    1: '%{company} is not liable for any errors in the content of the website. The services are offered to the user without any consideration and only entail for the User the obligation to comply with the established rules of use, therefore %{company} may suspend, withdraw or partially or totally cancel these services without prior notice. %{company} does not guarantee the availability and continuity of the website in terms of its operation, excluding %{company} from any liability in case of damages suffered by the User that may be derived from the lack of availability and continuity of the operation of the website and its services.',
                    2: 'These General Conditions will apply both to the promotional activity and the supply of information that is provided through the website and to the provision of the services offered by the website, therefore they will govern at all times, both for the simple navigation of the website as well as for the acquisition of goods and/or the provision of services within the context of the website, although these activities will be additionally subjected to both the General Conditions of Use, as well as the General Conditions of Contract and the Particular Conditions that could exist.',
                    3: 'It is the responsibility of each User to adopt all the appropriate technical measures to control the risks through open networks. %{company}, is not liable for possible damages derived from, and not limited to:',
                    4: 'Inferences, omissions, interruptions, computer viruses, breakdowns and/or disconnections in the operational functioning of the system or in the devices or equipment of the users, which prevent or delay the provision of services or navigation through the system;',
                    5: 'Delays or blockages in use caused by deficiencies or overloads of the Internet or other electronic systems;',
                    6: 'That may be caused by third parties through illegitimate interference;',
                    7: 'Impossibility of providing the service or allowing access for reasons not attributable to %{company}, due to the user, third parties or cases of force majeure;',
                    8: 'the privacy and security in the use of the web page by the user and/or the non-consensual access of unauthorized third parties.',
                },
                disputes: {
                    1: 'The provision of the service is governed by Spanish legislation, and any arbitration or dispute shall be resolved under the Courts and Tribunals of %{province}, to which the User expressly submits.',
                },
            },
            title: {
                legal: 'Legal Info',
                conditions: 'Terms of Use',
                liability: 'Your use of our Services',
                rights: 'Industrial and intellectual property rights',
                disclaimer: 'Disclaimer',
                disputes: 'Dispute Resolution',
            },
        },
    },
}

export default en
