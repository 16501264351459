import { useI18nProvider } from './useI18nProvider'
import { useCallback } from 'react'

export const useTranslate = () => {
    const { translate } = useI18nProvider()

    // Update the hook each time the provider changes
    return useCallback(
        (key: string, options: any = {}): string => translate(key, options),
        [translate]
    )
}
