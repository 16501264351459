import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Carousel from 'react-material-ui-carousel'
import { ImageConfig, ProductConfig, useTranslate } from '../../service'
import { styled } from '@mui/material'

const PREFIX = 'OrHero'

const classes = {
    root: `${PREFIX}-root`,
    picture: `${PREFIX}-picture`,
    image: `${PREFIX}-image`,
    counter: `${PREFIX}-counter`,
}

const StyledCarousel = styled('div')(({ theme }) => ({
    [`&.${classes.root}`]: {
        height: '396px',
        position: 'relative',
        [theme.breakpoints.up('md')]: {
            height: '506px',
            margin: '0 auto',
            width: '900px',
        },
    },
    [`& .${classes.picture}`]: {
        display: 'block',
        height: '396px',
        [theme.breakpoints.up('md')]: {
            height: '506px',
        },
    },
    [`& .${classes.image}`]: {
        objectFit: 'cover',
        height: '100%',
        width: '100%',
    },
    [`& .${classes.counter}`]: {
        color: 'white',
        backgroundColor: 'rgba(34, 34, 34, 0.66)',
        borderRadius: '4px',
        bottom: '16px',
        fontSize: '12px',
        fontWeight: 500,
        padding: '3px 10px',
        position: 'absolute',
        right: '16px',
        zIndex: 1,
    },
}))

export interface ProductCarouselProps {
    product: ProductConfig
}

export const ProductCarousel = (props: ProductCarouselProps) => {
    const { product } = props
    const { id, images, poster} = product

    const [item, setItem] = useState<number>(1)
    const translate = useTranslate()
    const alt = translate(`product.name.${id}`)

    const handleNext = (next?: number) => {
        if (next !== undefined) {
            setItem(next + 1)
        }
    }

    const handlePrev = (prev?: number) => {
        if (prev !== undefined) {
            setItem(prev + 1)
        }
    }

    const renderItem = (image: ImageConfig, key: number) => {
        const { src } = image

        return (
            <div
                className={classes.picture}
                key={key}
            >
                <img
                    alt={alt}
                    className={classes.image}
                    loading={'lazy'}
                    src={src}
                />
            </div>
        )
    }

    const items = [{ src: poster }, ...images]

    return (
        <StyledCarousel className={classes.root}>
            <Carousel
                animation='slide'
                autoPlay={false}
                duration={300}
                navButtonsAlwaysVisible
                navButtonsProps={{
                    style: {
                        opacity: 0.65,
                    }
                }}
                next={handleNext}
                prev={handlePrev}
            >
                {
                    items.map(renderItem)
                }
            </Carousel>
            <span className={classes.counter}>
                {`${item}/${items.length}`}
            </span>
        </StyledCarousel>
    )
}

ProductCarousel.propTypes = {
    product: PropTypes.object,
}
