import { Translate, TranslationDictionary } from './types'
import Polyglot from 'node-polyglot'

export const createTranslate = (locale: string, messages: TranslationDictionary, polyglotOptions: any = {}): Translate => {
    const phrases = { '': '', ...messages }
    const polyglot = new Polyglot({ locale, phrases, ...polyglotOptions })
    const translate = polyglot.t.bind(polyglot)

    return (key: string, options: any = {}): string => translate(key, options)
}
