import CloseIcon from '@mui/icons-material/Close'
import LanguageIcon from '@mui/icons-material/Language'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import clsx from 'clsx'
import {
    CHANGE_LOCALE, LOCALE_COOKIE,
    useCookieProvider,
    useLocale,
    useLocaleInfo,
    useLocaleLabel,
    useLogEvent,
    useSetLocale,
    useTranslate
} from '../service'
import {
    Container,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemText,
    SxProps,
    Typography,
    Tooltip,
    styled,
} from '@mui/material'

const PREFIX = 'OrLocaleSwitcher'

const classes = {
    paper: `${PREFIX}-paper`,
    container: `${PREFIX}-container`,
    wrap: `${PREFIX}-wrap`,
    title: `${PREFIX}-title`,
    close: `${PREFIX}-close`,
    divider: `${PREFIX}-divider`,
    item: `${PREFIX}-item`,
}

const StyledDrawer = styled(Drawer)(({ theme }) => ({
    [`& .${classes.paper}`]: {
        borderRadius: theme.spacing(1.5, 1.5, 0, 0),
        margin: '0 auto',
        maxWidth: '900px',
    },
    [`& .${classes.container}`]: {
        margin: theme.spacing(3, 0),
    },
    [`& .${classes.wrap}`]: {
        alignItems: 'center',
        display: 'flex',
        margin: theme.spacing(0, 0, 2),
    },
    [`& .${classes.title}`]: {
        alignItems: 'center',
        color: 'rgb(34,34,34)',
        display: 'flex',
        fontSize: '18px',
        fontWeight: 600,
        gap: theme.spacing(1),
        lineHeight: '22px',
        margin: theme.spacing(2, 0),
    },
    [`& .${classes.close}`]: {
        marginLeft: 'auto',
    },
    [`& .${classes.divider}`]: {
        marginBottom: theme.spacing(1),
    },
    [`& .${classes.item}`]: {
        border: '1px solid transparent',
        borderRadius: theme.spacing(1),
        cursor: 'pointer',
        [`&:focus, &:hover, &:active`]: {
            backgroundColor: 'rgb(247, 247, 247)',
        },
        [`&.selected`]: {
            borderColor: 'rgb(34, 34, 34)',
        },
    },
}))

export interface LocaleSwitcherProps {
    displayLabel?: boolean
    sx?: SxProps
    selectOnClick?: boolean
}

export const LocaleSwitcher = (props: LocaleSwitcherProps) => {
    const { displayLabel, sx, selectOnClick } = props

    const locale = useLocale()
    const locales = useLocaleInfo()
    const localeLabel = useLocaleLabel()
    const logEvent = useLogEvent()
    const setLocale = useSetLocale()
    const translate = useTranslate()
    const { consent, cookies } = useCookieProvider()

    const [open, setOpen] = useState<boolean>(false)

    const handleLocale = (newLocale: string) =>
        () => {
            setLocale(newLocale)
            logEvent(CHANGE_LOCALE, {
                from: locale,
                to: newLocale,
            })

            if (consent) {
                cookies.set(LOCALE_COOKIE, newLocale, {
                    maxAge: 90 * 84600000,
                })
            }

            if (selectOnClick) {
                toggleDrawer()
            }
        }

    const toggleDrawer = () => {
        setOpen(open => ! open)
    }

    const renderIconButton = () => (
        <IconButton 
            aria-label={translate('app.links.switchLocale')}
            sx={sx}
            onClick={toggleDrawer}
        >
            <LanguageIcon />
        </IconButton>
    )

    return (
        <>
            {
                displayLabel ? (
                    <div style={{
                        alignItems: 'center',
                        display: 'flex',
                    }}>
                        {
                            renderIconButton()
                        }
                        <Typography sx={{ margin: 0 }} variant='body2'>
                            {localeLabel}
                        </Typography>
                    </div>
                ) : (
                    <Tooltip title={translate('app.title.chooseLanguage')}>
                        {
                            renderIconButton()
                        }
                    </Tooltip>
                )
            }
            <StyledDrawer
                anchor='bottom'
                classes={{ paper: classes.paper }}
                open={open}
                onClose={toggleDrawer}
            >
                <Container
                    className={classes.container}
                    maxWidth='md'
                >
                    <div className={classes.wrap}>
                        <Typography className={classes.title}>
                            <LanguageIcon />
                            {translate('app.title.chooseLanguage')}
                        </Typography>
                        <Tooltip title={translate('app.title.close')}>
                            <IconButton className={classes.close} onClick={toggleDrawer}>
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <Divider className={classes.divider} />
                    <List>
                        {
                            locales.map(localeInfo => {
                                const { locale: loc, name } = localeInfo

                                // Only allow EN and ES
                                if (loc !== 'es' &&
                                    loc !== 'en') {
                                    return null
                                }

                                return (
                                    <ListItem
                                        className={clsx(classes.item, {
                                            selected: locale === loc,
                                        })}
                                        key={loc}
                                        onClick={handleLocale(loc)}
                                    >
                                        <ListItemText
                                            primary={loc.toUpperCase()}
                                            secondary={name}
                                        />
                                    </ListItem>
                                )
                            })
                        }
                    </List>
                </Container>
            </StyledDrawer>
        </>
    )
}

LocaleSwitcher.propTypes = {
    displayLabel: PropTypes.bool,
    sx: PropTypes.object,
}
