import React from 'react'
import { Container, Typography } from '@mui/material'
import { Helmet } from 'react-helmet-async'
import { StyledSection, classes } from './Styled'
import { useScrollToTop, useTranslate } from '../../service'

export const Faq = () => {
    const translate = useTranslate()

    useScrollToTop()

    return (
        <>
            <Helmet prioritizeSeoTags>
                <title>Ocean Rider · FAQ</title>
                <link rel='canonical' href='https://oceanridertenerife.es/faq' />
                <link rel='alternate' href='https://oceanridertenerife.es/faq?hl=en' hrefLang="en" />
            </Helmet>
            <StyledSection className={classes.root}>
                <Container maxWidth='md'>
                    <Typography className={classes.h1} component='h1'>
                        {translate('app.links.faq')}
                    </Typography>
                </Container>
            </StyledSection>
        </>
    )
}
