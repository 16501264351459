import * as cookie from 'cookie'
import { Cookie, CookieGetOptions, CookieParseOptions } from './types'

// noinspection SuspiciousTypeOfGuard
export const documentHasCookies = (): boolean =>
    typeof document === 'object' &&
    typeof document.cookie === 'string' &&
    typeof navigator === 'object' &&
    navigator.cookieEnabled

export const cleanCookies = (): void => {
    document.cookie.split(';').forEach((c: string) => {
        document.cookie = c
            .replace(/^ +/, '')
            .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/')
    })
}

export const parseCookies = (
    cookies?: string | object | null,
    options?: CookieParseOptions
): { [name: string]: Cookie } =>
    typeof cookies === 'string' ? cookie.parse(cookies, options) :
    typeof cookies === 'object' && cookies ? cookies : {}

export const isParsingCookie = (value: Cookie, doNotParse?: boolean): boolean => {
    if (typeof doNotParse === 'undefined') {
        // We guess if the cookie start with { or [, it has been serialized
        doNotParse = ! value || (value[0] !== '{' && value[0] !== '[' && value[0] !== '"')
    }

    return ! doNotParse
}

export const readCookie = (value: Cookie, options: CookieGetOptions = {}) => {
    const { doNotParse } = options

    if (isParsingCookie(value, doNotParse)) {
        try {
            return JSON.parse(value)
        } catch (e) {
            // Fallthrough
        }
    }

    return value
}
