import { DurationConfig, FeeConfig, ProductConfig } from './types'

export const getCurrentFee = (duration: number, durations: DurationConfig[]): FeeConfig => {
    for (const d of durations) {
        const { maxDuration, fees } = d

        if (duration === maxDuration) {
            return fees
        }
    }

    return durations[0].fees as FeeConfig
}

export const getAvailableDurations = (product: ProductConfig): number[] => {
    const { durations } = product
    const available = []

    for (const d of durations) {
        const { effectiveDuration } = d

        available.push(effectiveDuration)
    }

    return available
}

export const getEffectiveDuration = (product: ProductConfig): number => {
    const { durations } = product

    for (const d of durations) {
        const { effectiveDuration } = d

        return effectiveDuration
    }

    return durations[0].effectiveDuration as number
}

export const getPrice = (product: ProductConfig): number => {
    const { durations } = product

    for (const d of durations) {
        const { fees: { resident }} = d

        return resident
    }

    return durations[0].fees.resident as number
}

// @deprecated
export const getTicketUrl = (product: ProductConfig): string | undefined => {
    const { durations } = product

    for (const d of durations) {
        const { fees: { ticketUrl }} = d

        return ticketUrl
    }

    return undefined
}

export const getTicketUrlForChoiceAt = (product?: ProductConfig, index: number = 0): string | undefined => {
    if (! product) {
        return undefined
    }
    
    const { durations } = product

    if (durations[index]) {
        const { fees: { ticketUrl }} = durations[index]

        return ticketUrl
    }

    return undefined
}

export const getCurrentTicketUrl = (duration: number, durations: DurationConfig[]): string | undefined => {
    for (const d of durations) {
        const { maxDuration, fees: { ticketUrl }} = d

        if (duration === maxDuration) {
            return ticketUrl
        }
    }

    return undefined
}
