import { useLocale } from '../i18n'

export const useCurrency = () => {
    const locale = useLocale()

    return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: 'EUR',
    }).format
}
