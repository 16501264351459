import { Config } from './types'

/*
const images = [
    {
        src: '/asset/PSX_20210204_162615.jpg',
        rows: 2,
        cols: 2,
    },
    {
        src: '/asset/PSX_20210204_160917.jpg',
    },
    {
        src: '/asset/PSX_20210204_161240.jpg',
    },
    {
        src: '/asset/PSX_20210204_162005.jpg',
        cols: 2,
    },
    {
        src: '/asset/PSX_20210204_175329.jpg',
    },
    {
        src: '/asset/PSX_20210204_175329.jpg',
    },
    {
        src: '/asset/PSX_20210204_183605.jpg',
        rows: 2,
        cols: 2,
    },
]
*/

export const config: Config = {
    debug: false,
    url: process.env.PUBLIC_URL,
    stadiaMapsApiKey: '51fcaa20-ea14-42bb-a6bc-a36fe0ca343d',
    brand: {
        name: 'Ocean Rider Tenerife',
        shortName: 'Ocean Rider',
    },
    company: {
        id: 'B782356834',
        registry: '(Registro Mercantil de S/C de Tenerife)',
    },
    contact: {
        emailAddress: 'watersportsradazul@gmail.com',
        facebook: 'https://www.facebook.com/OceanRiderTenerife/',
        instagram: 'https://www.instagram.com/ocean_rider_tenerife/',
        phone: '(+34) 675 10 39 38',
        www: 'https://www.oceanridertenerife.es/',
    },
    hero: {
        desktop: '/asset/p-freeride05.webp',
        mobile: '/asset/p-freeridesq.webp',
    },
    location: {
        address: 'Marina Puerto Deportivo',
        locality: 'Radazul',
        postalCode: '38109',
        province: 'Santa Cruz de Tenerife',
        url: 'https://goo.gl/maps/vc7ufgmnTzyMVpdt6',
    },
    layout: {
        sm: 1,
        md: 2,
        lg: 2,
    },
    products: [
        {
            id: 'radazul-bay',
            route: false,
            distance: -1,
            durations: [
                {
                    effectiveDuration: 30,
                    minDuration: 30,
                    maxDuration: 30,
                    fees: {
                        resident: 65.00,
                        nonResident: 65.00,
                        //ticketUrl: 'https://bonosvip.com/tenerife/6532-ocean-rider-radazul.html',
                    }
                },
                {
                    effectiveDuration: 60,
                    minDuration: 60,
                    maxDuration: 60,
                    fees: {
                        resident: 100.00,
                        nonResident: 100.00,
                    }
                }
            ],
            harborFees: 1.0,
            photoAndVideo: 5.0,
            residentDiscount: 10,
            minAge: 16,
            minPassengers: 1,
            maxPassengers: 2,
            withInstructor: true,
            includesSwim: true,
            includesBriefing: true,
            includesLifejacket: true,
            includesLockerRoom: true,
            includesInsurance: true,
            includesPhotosAndVideos: true,
            cancellableUntil: 48,
            zoom: 14,
            stops: [],
            poster: '/asset/p-radazul-01-sq.webp',
            square: '/asset/p-radazul-01-sq.webp',
            images: Array.from(Array(15).keys()).map(key => ({
                src: `/asset/p_radazul_${1 + key}.min.webp`,
            })),
        },
        {
            id: 'candelaria',
            route: true,
            distance: 10.0,
            durations: [
                {
                    approximate: true,
                    effectiveDuration: 40,
                    minDuration: 40,
                    maxDuration: 40,
                    fees: {
                        resident: 90.00,
                        nonResident: 90.00,
                    },
                },
            ],
            harborFees: 1.0,
            photoAndVideo: 5.0,
            minAge: 16,
            minPassengers: 1,
            maxPassengers: 2,
            withInstructor: true,
            includesSwim: true,
            includesBriefing: true,
            includesLifejacket: true,
            includesLockerRoom: true,
            includesInsurance: true,
            includesPhotosAndVideos: true,
            cancellableUntil: 48,
            zoom: 13,
            stops: [
                {
                    id: 'candelaria',
                    lat: 28.3519656,
                    lng: -16.3701421,
                    code: 'https://goo.gl/maps/kEKFP3ytrABG3HeL9',
                },
            ],
            poster: '/asset/p-candelaria-01.webp',
            square: '/asset/p-candelaria-01-sq.webp',
            images: Array.from(Array(11).keys()).map(key => ({
                src: `/asset/p_candelaria_${1 + key}.min.webp`,
            })),
        },
        {
            id: 'malpais-guimar',
            route: true,
            distance: 20.0,
            durations: [
                {
                    approximate: true,
                    effectiveDuration: 80,
                    minDuration: 80,
                    maxDuration: 80,
                    fees: {
                        resident: 140.00,
                        nonResident: 140.00,
                    },
                },
            ],
            harborFees: 1.0,
            photoAndVideo: 5.0,
            minAge: 16,
            minPassengers: 1,
            maxPassengers: 2,
            withInstructor: true,
            includesSwim: true,
            includesBriefing: true,
            includesLifejacket: true,
            includesLockerRoom: true,
            includesInsurance: true,
            includesPhotosAndVideos: true,
            cancellableUntil: 48,
            zoom: 12,
            stops: [
                {
                    id: 'malpais-guimar',
                    lat: 28.3107728,
                    lng: -16.3644566,
                    code: 'https://goo.gl/maps/9So6yn9PTPcJivAq9',
                },
            ],
            poster: '/asset/p-guimar-01-sq.webp',
            square: '/asset/p-guimar-01-sq.webp',
            images: Array.from(Array(9).keys()).map(key => ({
                src: `/asset/p_guimar_${1 + key}.min.webp`,
            })),
        },
/*
        {
            id: 'punta-pachona',
            route: true,
            distance: 10.0,
            durations: [
                {
                    effectiveDuration: 30,
                    minDuration: 30,
                    maxDuration: 45,
                    fees: {
                        resident: 65.00,
                        nonResident: 65.00,
                    },
                },
            ],
            harborFees: 1.0,
            photoAndVideo: 5.0,
            minAge: 16,
            minPassengers: 1,
            maxPassengers: 2,
            withInstructor: true,
            includesSwim: true,
            includesBriefing: true,
            includesLifejacket: true,
            includesLockerRoom: true,
            includesInsurance: true,
            includesPhotosAndVideos: true,
            cancellableUntil: 48,
            zoom: 14,
            stops: [
                {
                    id: 'boca-cangrejo',
                    lat: 28.405585,
                    lng: -16.314240,
                    code: 'https://goo.gl/maps/CRGmLWkCcEP7J9Uw6',
                },
                {
                    id: 'punta-pachona',
                    lat: 28.4174316,
                    lng: -16.2976658,
                    code: 'https://goo.gl/maps/SgcLoFPSqM17jEaj7',
                }
            ],
            poster: '/asset/p-freeride01-sq.webp',
            square: '/asset/p-freeride01-sq.webp',
            images,
        },
*/
    ]
}
