import React from 'react'
import { Container, Typography } from '@mui/material'
import { Helmet } from 'react-helmet-async'
import { StyledSection, classes } from './Styled'
import { useConfig, useScrollToTop, useTranslate } from '../../service'

export const TermsAndConditions = () => {
    const translate = useTranslate()
    const { brand, contact, company, location } = useConfig()
    const { name } = brand
    const { id } = company
    const { emailAddress } = contact
    const { address, locality, postalCode, province } = location
    const fullAddress = `${address}, ${postalCode} ${locality}, ${province}`

    useScrollToTop()

    return (
        <>
            <Helmet prioritizeSeoTags>
                <title>Ocean Rider · {translate('app.links.terms')}</title>
                <link rel='canonical' href='https://oceanridertenerife.es/terms-and-conditions' />
                <link rel='alternate' href='https://oceanridertenerife.es/terms-and-conditions?hl=en' hrefLang="en" />
            </Helmet>
            <StyledSection className={classes.root}>
                <Container maxWidth='md'>
                    <Typography className={classes.h1} component='h1'>
                        {translate('app.links.terms')}
                    </Typography>
                    <Typography variant='body2'>
                        {translate('terms.text.updated')}
                    </Typography>

                    <Typography className={classes.h2} component='h2'>
                        {'1. '}
                        {translate('terms.title.legal')}
                    </Typography>
                    <Typography variant='body1'>
                        {translate('terms.text.legal.1', { address: fullAddress, company: name, emailAddress, id })}
                    </Typography>
                    <Typography variant='body1'>
                        {translate('terms.text.legal.2')}
                    </Typography>
                    <Typography variant='body1'>
                        {translate('terms.text.legal.3')}
                    </Typography>
                    <Typography variant='body1'>
                        {translate('terms.text.legal.4', { company: name })}
                    </Typography>

                    <Typography className={classes.h2} component='h2'>
                        {'2. '}
                        {translate('terms.title.conditions')}
                    </Typography>
                    <Typography variant='body1'>
                        {translate('terms.text.conditions.1', { company: name })}
                    </Typography>
                    <Typography variant='body1'>
                        {translate('terms.text.conditions.2')}
                    </Typography>
                    <Typography variant='body1'>
                        {translate('terms.text.conditions.3')}
                    </Typography>

                    <Typography className={classes.h2} component='h2'>
                        {'3. '}
                        {translate('terms.title.liability')}
                    </Typography>
                    <Typography variant='body1'>
                        {translate('terms.text.liability.1')}
                    </Typography>
                    <ol>
                        <li>
                            <Typography variant='body1'>
                                {translate('terms.text.liability.2', { company: name })}
                            </Typography>
                        </li>
                        <li>
                            <Typography variant='body1'>
                                {translate('terms.text.liability.3', { company: name })}
                            </Typography>
                        </li>
                        <li>
                            <Typography variant='body1'>
                                {translate('terms.text.liability.4', { company: name })}
                            </Typography>
                        </li>
                        <li>
                            <Typography variant='body1'>
                                {translate('terms.text.liability.5')}
                            </Typography>
                        </li>
                        <li>
                            <Typography variant='body1'>
                                {translate('terms.text.liability.6')}
                            </Typography>
                        </li>
                        <li>
                            <Typography variant='body1'>
                                {translate('terms.text.liability.7', { company: name })}
                            </Typography>
                        </li>
                        <li>
                            <Typography variant='body1'>
                                {translate('terms.text.liability.8', { company: name })}
                            </Typography>
                        </li>
                    </ol>

                    <Typography className={classes.h2} component='h2'>
                        {'4. '}
                        {translate('terms.title.rights')}
                    </Typography>
                    <Typography variant='body1'>
                        {translate('terms.text.rights.1', { company: name })}
                    </Typography>

                    <Typography className={classes.h2} component='h2'>
                        {'5. '}
                        {translate('terms.title.disclaimer')}
                    </Typography>
                    <Typography variant='body1'>
                        {translate('terms.text.disclaimer.1', { company: name })}
                    </Typography>
                    <Typography variant='body1'>
                        {translate('terms.text.disclaimer.2')}
                    </Typography>
                    <Typography variant='body1'>
                        {translate('terms.text.disclaimer.3', { company: name })}
                    </Typography>
                    <ol>
                        <li>
                            <Typography variant='body1'>
                                {translate('terms.text.disclaimer.4')}
                            </Typography>
                        </li>
                        <li>
                            <Typography variant='body1'>
                                {translate('terms.text.disclaimer.5')}
                            </Typography>
                        </li>
                        <li>
                            <Typography variant='body1'>
                                {translate('terms.text.disclaimer.6')}
                            </Typography>
                        </li>
                        <li>
                            <Typography variant='body1'>
                                {translate('terms.text.disclaimer.7', { company: name })}
                            </Typography>
                        </li>
                        <li>
                            <Typography variant='body1'>
                                {translate('terms.text.disclaimer.8')}
                            </Typography>
                        </li>
                    </ol>

                    <Typography className={classes.h2} component='h2'>
                        {'6. '}
                        {translate('terms.title.disputes')}
                    </Typography>
                    <Typography variant='body1'>
                        {translate('terms.text.disputes.1', { province })}
                    </Typography>
                </Container>
            </StyledSection>
        </>
    )
}
