import React from 'react'
import { Container, Typography, styled, useMediaQuery, useTheme } from '@mui/material'
import { OceanRiderIcon } from './icon'
import { useConfig, useTranslate } from '../service'

const PREFIX = 'OrHero'

const classes = {
    root: `${PREFIX}-root`,
    hero: `${PREFIX}-hero`,
    overlay: `${PREFIX}-overlay`,
    bg: `${PREFIX}-bg`,
    picture: `${PREFIX}-picture`,
    container: `${PREFIX}-container`,
    logo: `${PREFIX}-logo`,
    motto: `${PREFIX}-motto`,
    text: `${PREFIX}-text`,
}

const StyledHero = styled('div')(({ theme }) => ({
    [`&.${classes.root}`]: {
        borderBottom: '1px solid rgb(221, 221, 221)',
        height: '600px',
        marginTop: theme.spacing(8),
        position: 'relative',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            height: '400px',
        },
    },
    [`& .${classes.container}`]: {
        alignItems: 'flex-start',
        display: 'flex',
        flexDirection: 'column',
        height: '600px',
        justifyContent: 'end',
        [theme.breakpoints.down('md')]: {
            height: '400px',
        },
    },
    [`& .${classes.bg}`]: {
        bottom: 0,
        left: 0,
        overflow: 'hidden',
        position: 'absolute',
        right: 0,
        top: 0,
    },
    [`& .${classes.picture}`]: {
        bottom: 0,
        left: 0,
        overflow: 'hidden',
        position: 'absolute',
        right: 0,
        top: 0,
    },
    [`& .${classes.hero}`]: {
        height: '100%',
        objectFit: 'cover',
        width: '100%',
        zIndex: 1,
    },
    [`& .${classes.overlay}`]: {
        background: `linear-gradient(to top, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.2) 70%,rgba(0,0,0,0) 100%)`,
        bottom: 0,
        height: '50%',
        left: 0,
        position: 'absolute',
        right: 0,
        zIndex: 2,
    },
    [`& .${classes.logo}`]: {
        color: 'white',
        display: 'block',
        height: '52px',
        width: '220px',
        zIndex: 2,
    },
    [`& .${classes.text}`]: {
        color: 'white',
        display: 'block',
        fontSize: '20px',
        fontWeight: 600,
        margin: theme.spacing(2, 0, 4),
        zIndex: 2,
    },
}))

export const Hero = () => {
    const { hero } = useConfig()
    const translate = useTranslate()
    const theme = useTheme()
    const isSmall = useMediaQuery(theme.breakpoints.down('md'))
    const { desktop, mobile } = hero

    return (
        <StyledHero className={classes.root}>
            <picture className={classes.picture}>
                <img
                    alt={translate('brand.name')}
                    className={classes.hero}
                    src={isSmall ? (mobile || desktop) : desktop }
                />
                <div className={classes.overlay} />
            </picture>
            {/*
            <picture className={classes.picture}>
                <Background className={classes.bg} />
                <div className={classes.overlay} />
            </picture>
            */}
            <Container className={classes.container} maxWidth='md'>
                <OceanRiderIcon className={classes.logo} />
                <Typography className={classes.text}>
                    {translate('brand.hash')}
                </Typography>
            </Container>
        </StyledHero>
    )
}
