import PropTypes from 'prop-types'
import React from 'react'
import { ProductConfig, useTranslate } from '../../service'
import { Typography } from '@mui/material'

export interface ProductCancellationProps {
    product: ProductConfig
}

export const ProductCancellation = (props: ProductCancellationProps) => {
    const { product } = props
    const { cancellableUntil } = product

    const translate = useTranslate()

    return (
        <div>
            <Typography component='h2' variant='h2' sx={{ pt: 4 }}>
                {translate(`product.cancellation.policy`)}
            </Typography>
            <Typography variant='body2' sx={{ mb: 8 }}>
                {
                    cancellableUntil > 0 ?
                        translate(`product.cancellation.cancellableUntil`, { cancellableUntil }) :
                        translate(`product.cancellation.notAvailable`)
                }
            </Typography>
        </div>
    )
}

ProductCancellation.propTypes = {
    product: PropTypes.object,
}
