import React, { ReactNode } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Link as ReactRouterLink, LinkProps as ReactRouterLinkProps } from 'react-router-dom'
import { Link as MuiLink, Stack, SxProps, styled } from '@mui/material'

const PREFIX = 'OrLink'

const classes = {
    link: `${PREFIX}-link`,
    stack: `${PREFIX}-stack`,
    icon: `${PREFIX}-icon`,
}

const MuiRouterLink = (props: ReactRouterLinkProps) => (
    <MuiLink component={ReactRouterLink} {...props} />
)

const StyledLink = styled(MuiRouterLink)({
    [`&.${classes.link}`]: {
        color: 'rgb(34,34,34)',
    },
    [`&.${classes.stack}`]: {
    },
    [`&.${classes.icon}`]: {
        color: 'rgb(34,34,34)',
    },
})

export interface LinkProps extends ReactRouterLinkProps {
    className?: string
    icon?: ReactNode
    sx?: SxProps
    underline?: boolean
    variant: string
}

export const Link = (props: LinkProps) => {
    const { children, className, icon, to, underline, ...rest } = props

    const renderLink = () => (
        <StyledLink
            className={clsx(classes.link, className)}
            to={to}
            sx={{
                textDecoration: underline ? 'underline' : 'none',
            }}
            {...rest}
        >
            {children}
        </StyledLink>
    )

    if (icon) {
        return (
            <Stack
                alignItems='flex-start'
                className={classes.stack}
                direction='row'
                justifyContent='flex-start'
                spacing={0.5}
            >
                {icon}
                {renderLink()}
            </Stack>
        )
    }

    return renderLink()
}

Link.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    icon: PropTypes.node,
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    underline: PropTypes.bool,
    variant: PropTypes.string,
}

Link.defaultProps = {
    underline: false,
    variant: 'body2',
}
