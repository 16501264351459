import React from 'react'
import clsx from 'clsx'
import { Container, Typography } from '@mui/material'
import { Helmet } from 'react-helmet-async'
import { StyledSection, classes } from './Styled'
import { useConfig, useScrollToTop, useTranslate } from '../../service'

export const AboutUs = () => {
    const translate = useTranslate()
    const { company, contact, location } = useConfig()
    const { id, registry } = company
    const { facebook, instagram, emailAddress, phone } = contact
    const { address, locality, postalCode, province } = location

    useScrollToTop()

    return (
        <>
            <Helmet prioritizeSeoTags>
                <title>Ocean Rider · {translate('app.links.aboutUs')}</title>
                <link rel='canonical' href='https://oceanridertenerife.es/about-us' />
                <link rel='alternate' href='https://oceanridertenerife.es/about-us?hl=en' hrefLang="en" />
            </Helmet>
            <StyledSection className={classes.root}>
                <Container maxWidth='md'>
                    <Typography className={classes.h1} component='h1'>
                        {translate('app.title.data')}
                    </Typography>
                    <Typography
                        className={classes.text}
                        component='h3'
                    >
                        <span className={classes.bold}>{translate('company.label.id')}</span>
                        <span>{': ' + id}</span>
                        <br/>
                        <span className={classes.bold}>{translate('company.label.registry')}</span>
                        <span>{': ' + registry}</span>
                    </Typography>
                    <Typography
                        className={clsx(classes.h3, classes.noGutter)}
                        component='h3'
                    >
                        {translate('app.title.location')}
                    </Typography>
                    <Typography
                        className={classes.text}
                        component='h3'
                    >
                        {address}
                        <br/>
                        {postalCode}
                        {' '}
                        {locality}
                        <br/>
                        {province}
                        <br/>
                    </Typography>
                    <Typography
                        className={clsx(classes.h3, classes.noGutter)}
                        component='h3'
                    >
                        {translate('app.title.contactUs')}
                    </Typography>
                    <Typography
                        className={clsx(classes.text, classes.noGutter)}
                        component='p'
                    >
                        {emailAddress}
                        <br/>
                        {phone}
                    </Typography>
                    <Typography
                        className={clsx(classes.h3, classes.noGutter)}
                        component='h3'
                    >
                        {translate('app.title.social')}
                    </Typography>
                    <Typography>
                        <a
                            className={classes.link}
                            href={instagram}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            Instagram
                        </a>
                        <br/>
                        <a
                            className={classes.link}
                            href={facebook}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            Facebook
                        </a>
                    </Typography>
                </Container>
            </StyledSection>
        </>
    )
}
