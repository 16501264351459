import PropTypes from 'prop-types'
import React, { ReactNode } from 'react'
import { ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material'

export interface ProductExtraProps {
    icon: ReactNode
    primaryText: string
    secondaryText?: string
}

export const ProductExtra = (props: ProductExtraProps) => {
    const { icon, primaryText, secondaryText } = props

    return (
        <ListItem alignItems='flex-start'>
            <ListItemIcon>
                {icon}
            </ListItemIcon>
            <ListItemText
                primary={
                    <Typography component='p' variant='highlight1'>
                        {primaryText}
                    </Typography>
                }
                secondary={
                    <Typography variant='body2'>
                        {secondaryText}
                    </Typography>
                }
            />
        </ListItem>
    )
}

ProductExtra.propTypes = {
    icon: PropTypes.node,
    primaryText: PropTypes.string,
    secondaryText: PropTypes.string,
}
