import PropTypes from 'prop-types'
import React from 'react'
import markerIconPng from 'leaflet/dist/images/marker-icon.png'
import { Icon } from 'leaflet'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import { Typography, styled } from '@mui/material'
import { OPEN_IN_MAPS, PlaceConfig, ProductConfig, useConfig, useLogEvent, useTranslate } from '../../service'

const PREFIX = 'OrMap'

const classes = {
    root: `${PREFIX}-root`,
}

const StyledMap = styled('div')(({ theme }) => ({
    [`&.${classes.root}`]: {
        marginTop: theme.spacing(4),
        width: '100%',
        height: 400,
        zIndex: -1,
    },
    [`& .leaflet-container`]: {
        height: 400,
        width: '100%',
        zIndex: 0,
    },
    [`& .leaflet-marker-pane img`]: {
        marginLeft: '-25px',
        marginTop: '-41px',
    },
}))

export interface ProductMapProps {
    attribution: string
    lat: number
    lng: number
    maxZoom: number
    product: ProductConfig
    scrollWheelZoom: boolean
    tileLayer: string
}

const computeActualMapCenter = (initialLat: number, initialLng: number, stops: PlaceConfig[]): { centerLat: number, centerLng: number } => {
    let centerLat = initialLat
    let centerLng = initialLng

    if (stops.length) {
        for (const stop of stops) {
            const { lat, lng } = stop

            centerLat += lat
            centerLng += lng
        }

        centerLat /= (1 + stops.length)
        centerLng /= (1 + stops.length)
    }

    return { centerLat, centerLng }
}

export const renderMarkerWithIcon = (
    key: number,
    lat: number,
    lng: number,
    primaryText: string,
    secondaryText: string,
    mapText?: string,
    code?: string,
    onClick?: () => void
) => (
    <Marker
        icon={new Icon({ iconUrl: markerIconPng, })}
        key={key}
        position={[lat, lng]}
    >
        <Popup>
            <Typography
                component='p'
                variant='highlight2'
            >
                {primaryText}
            </Typography>
            <Typography
                component='p'
                variant='body2'
            >
                {secondaryText}
                {
                    mapText ? (
                        <>
                            <br/>
                            <a
                                rel='noopener noreferrer'
                                target='_blank'
                                title={primaryText}
                                href={code || `https://www.google.com/maps/place/${lat},${lng}`}
                                onClick={onClick}
                            >
                                {mapText}
                            </a>
                        </>
                    ) : null
                }
            </Typography>
        </Popup>
    </Marker>
)

export const ProductMap = (props: ProductMapProps) => {
    const {
        attribution,
        lat,
        lng,
        maxZoom,
        product,
        scrollWheelZoom,
        tileLayer,
    } = props

    const { stops, zoom } = product
    const { centerLat, centerLng } = computeActualMapCenter(lat, lng, stops)
    const { stadiaMapsApiKey } = useConfig()
    const logEvent = useLogEvent()
    const translate = useTranslate()

    const handleOpenInMaps = (place: string) =>
        () => {
            logEvent(OPEN_IN_MAPS, {
                place,
                where: 'product',
            })
        }

    if (! stops) {
        return null
    }

    return (
        <StyledMap className={classes.root}>
            <MapContainer
                center={[centerLat, centerLng]}
                scrollWheelZoom={scrollWheelZoom}
                style={{ height: 400, width: '100%' }}
                zoom={zoom}
            >
                <TileLayer
                    attribution={attribution}
                    maxZoom={maxZoom}
                    url={`${tileLayer}?api_key=${stadiaMapsApiKey}`}
                />
                {
                    renderMarkerWithIcon(0, lat, lng,
                        translate(`product.stop.ocean-rider`),
                        translate(`product.stop.origin`)
                    )
                }
                {
                    stops.map((stop: PlaceConfig, i) => {
                        const { id, lat, lng, code } = stop

                        return renderMarkerWithIcon(i, lat, lng,
                            translate(`product.stop.${id}`),
                            translate(`product.stop.label`),
                            translate('product.details.seeOnMap'),
                            code,
                            handleOpenInMaps(id)
                        )
                    })
                }
            </MapContainer>
        </StyledMap>
    )
}

ProductMap.propTypes = {
    attribution: PropTypes.string,
    lat: PropTypes.number,
    lng: PropTypes.number,
    maxZoom: PropTypes.number,
    product: PropTypes.object,
    scrollWheelZoom: PropTypes.bool,
    tileLayer: PropTypes.string,
}

// See https://github.com/leaflet-extras/leaflet-providers
ProductMap.defaultProps = {
    attribution: '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors',
    lat: 28.40071,
    lng: -16.32352,
    maxZoom: 20,
    scrollWheelZoom: false,
    tileLayer: 'https://tiles.stadiamaps.com/tiles/outdoors/{z}/{x}/{y}{r}.png',
}
