import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CookieIcon from '@mui/icons-material/Cookie'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import { StyledSection, classes } from './Styled'
import { useCookieProvider, useScrollToTop, useTranslate } from '../../service'
import {
    Button,
    Container,
    Divider,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    styled,
    useTheme
} from '@mui/material'

const StyledAnchor = styled('a')({
    color: 'rgb(34,34,34)',
    '&:hover': {
        fontWeight: 600,
    },
})

export const CookiePolicy = () => {
    const { consent, decline } = useCookieProvider()
    const theme = useTheme()
    const translate = useTranslate()

    useScrollToTop()

    const handleOptOut = () => {
        decline()
    }

    return (
        <>
            <Helmet prioritizeSeoTags>
                <title>Ocean Rider · {translate('app.links.cookies')}</title>
                <link rel='canonical' href='https://oceanridertenerife.es/cookie-policy' />
                <link rel='alternate' href='https://oceanridertenerife.es/cookie-policy?hl=en' hrefLang="en" />
            </Helmet>
            <StyledSection className={classes.root}>
                <Container maxWidth='md'>
                    <Typography className={classes.h1} component='h1'>
                        {translate('app.links.cookies')}
                    </Typography>
                    <Typography variant='body2'>
                        {translate('cookies.text.updated')}
                    </Typography>
                    <Typography className={classes.h2} component='h2'>
                        {translate('cookies.title.what')}
                    </Typography>
                    <Typography variant='body1'>
                        {translate('cookies.text.what')}
                    </Typography>
                    <Typography className={classes.h2} component='h2'>
                        {translate('cookies.title.why')}
                    </Typography>
                    <Typography variant='body1'>
                        {translate('cookies.text.why')}
                    </Typography>
                    <Typography className={classes.h2} component='h2'>
                        {translate('cookies.title.types')}
                    </Typography>
                    <Typography className={classes.h3} component='h3'>
                        {translate('cookies.title.technical')}
                    </Typography>
                    <Typography variant='body1'>
                        {translate('cookies.text.technical')}
                    </Typography>
                    <Typography className={classes.h3} component='h3'>
                        {translate('cookies.title.performance')}
                    </Typography>
                    <Typography variant='body1'>
                        {translate('cookies.text.performance')}
                    </Typography>
                    <Typography className={classes.h2} component='h2'>
                        {translate('cookies.title.list')}
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table sx={{ width: '100%' }} aria-label={'cookies'}>
                            <TableHead>
                                <TableRow>
                                    <TableCell><b>Cookie</b></TableCell>
                                    <TableCell><b>{translate('cookies.desc.description')}</b></TableCell>
                                    <TableCell><b>{translate('cookies.desc.provider')}</b></TableCell>
                                    <TableCell><b>{translate('cookies.desc.expiry')}</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant='body2'>consent</Typography>
                                    </TableCell>
                                    <TableCell>{translate('cookies.desc.consent')}</TableCell>
                                    <TableCell>{translate('brand.name')}</TableCell>
                                    <TableCell>{translate('cookies.desc.days', { days: 90 })}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant='body2'>locale</Typography>
                                    </TableCell>
                                    <TableCell>{translate('cookies.desc.locale')}</TableCell>
                                    <TableCell>{translate('brand.name')}</TableCell>
                                    <TableCell>{translate('cookies.desc.days', { days: 90 })}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant='body2'>_ga</Typography>
                                    </TableCell>
                                    <TableCell>{translate('cookies.desc.ga')}</TableCell>
                                    <TableCell>Google Analytics</TableCell>
                                    <TableCell>{translate('cookies.desc.years', { years: 2 })}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant='body2'>_gid</Typography>
                                    </TableCell>
                                    <TableCell>{translate('cookies.desc.gid')}</TableCell>
                                    <TableCell>Google Analytics</TableCell>
                                    <TableCell>{translate('cookies.desc.day')}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant='body2'>_gat</Typography>
                                    </TableCell>
                                    <TableCell>{translate('cookies.desc.gat')}</TableCell>
                                    <TableCell>Google Analytics</TableCell>
                                    <TableCell>{translate('cookies.desc.minute')}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Typography className={classes.h2} component='h2'>
                        {translate('cookies.title.manage')}
                    </Typography>
                    <Typography variant='body1'>
                        {translate('cookies.text.manage')}
                    </Typography>
                    <Typography variant='body1'>
                        {translate('cookies.text.howto')}
                    </Typography>
                    <ul>
                        <li>
                            <Typography variant='body1'>
                                <StyledAnchor href='https://support.google.com/accounts/answer/61416?hl=es' rel='noopener noreferrer' target='_blank'>Chrome</StyledAnchor>
                            </Typography>
                        </li>
                        <li>
                            <Typography variant='body1'>
                                <StyledAnchor href='https://support.mozilla.org/en-US/kb/block-websites-storing-cookies-site-data-firefox' rel='noopener noreferrer' target='_blank'>Firefox</StyledAnchor>
                            </Typography>
                        </li>
                        <li>
                            <Typography variant='body1'>
                                <StyledAnchor href='https://www.microsoft.com/en-us/edge/learning-center/how-to-manage-and-clear-your-cache-and-cookies?form=MA13I2' rel='noopener noreferrer' target='_blank'>Edge</StyledAnchor>
                            </Typography>
                        </li>
                        <li>
                            <Typography variant='body1'>
                                <StyledAnchor href='https://support.apple.com/es-es/HT201265' rel='noopener noreferrer' target='_blank'>Safari</StyledAnchor>
                            </Typography>
                        </li>
                        <li>
                            <Typography variant='body1'>
                                <StyledAnchor href='https://help.opera.com/en/latest/web-preferences/#cookies' rel='noopener noreferrer' target='_blank'>Opera</StyledAnchor>
                            </Typography>
                        </li>
                    </ul>
                    <Typography variant='body1'>
                        {translate('cookies.text.more')}
                        <StyledAnchor href='https://www.aepd.es/es/areas-de-actuacion/internet-y-redes-sociales/protege-tu-privacidad' rel='noopener noreferrer' target='_blank'>
                            {translate('cookies.text.aepd')}
                        </StyledAnchor>
                        {'.'}
                    </Typography>
                    <Divider sx={{ margin: theme.spacing(4, 0) }} />
                    {
                        consent === undefined ? (
                            <Typography
                                sx={{ display: 'flex', alignItems: 'center' }}
                                variant='body2'
                            >
                                <InfoIcon
                                    color='primary'
                                    fontSize='small'
                                    sx={{ marginRight: theme.spacing(1) }}
                                />
                                {translate('cookies.consent.undefined')}
                            </Typography>
                        ) : consent ? (
                            <>
                                <Typography
                                    sx={{ display: 'flex', alignItems: 'center' }}
                                    variant='body2'
                                >
                                    <CookieIcon
                                        color='success'
                                        fontSize='small'
                                        sx={{ marginRight: theme.spacing(1) }}
                                    />
                                    {translate('cookies.consent.accepted')}
                                </Typography>
                                <Button
                                    color='primary'
                                    onClick={handleOptOut}
                                    sx={{ marginTop: theme.spacing(2), textTransform: 'none' }}
                                    variant='contained'
                                >
                                    {translate('cookies.consent.optOut')}
                                </Button>
                            </>
                            ) : (
                                <Typography
                                    sx={{ display: 'flex', alignItems: 'center' }}
                                    variant='body2'
                                >
                                    <CheckCircleIcon
                                        color='success'
                                        fontSize='small'
                                        sx={{ marginRight: theme.spacing(1) }}
                                    />
                                    {translate('cookies.consent.declined')}
                                </Typography>
                            )
                    }
                </Container>
            </StyledSection>
        </>
    )
}
