import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

const SimpleSkiJetIcon = (props: SvgIconProps, ref: any) => (
    <SvgIcon
        data-testid='SimpleSkiJetIcon'
        ref={ref}
        height={24}
        width={24}
        viewBox={'0 0 24 24'}
        {...props}
    >
        <path d='m 19.634795,15.762116 c 1.95,-0.43 3.37,-1.4 3.37,-2.8 0,-1 -8,-7.9999996 -8,-7.9999996 h -3 v 2 h 2.25 l 1.45,1.3 -3.7,2.6999996 -9.4999996,-0.9999996 -1.5,2.9999996 1.5893126,3.003063 10.410687,-0.0031 h 4 c 1.401611,0.06864 2.633152,-0.180304 2.63,-0.2 z'/>
    </SvgIcon>
)

// noinspection JSUnresolvedVariable
SimpleSkiJetIcon.muiName = SvgIcon.muiName

// noinspection JSCheckFunctionSignatures
export default React.memo(React.forwardRef(SimpleSkiJetIcon))
