import React, { useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { AnalyticsProvider, useFirebaseApp } from 'reactfire'
import { useConsent } from '../service'
import { Layout } from './Layout'
import { AboutUs, CookiePolicy, Faq, Home, NotFound, PrivacyPolicy, TermsAndConditions } from './main'
import { PageView } from './PageView'
import { Product } from './product'
import { getAnalytics, setConsent } from 'firebase/analytics'

export const Router = () => {
    const app = useFirebaseApp()
    const analytics = getAnalytics(app)
    const consent = useConsent()

    useEffect(() => {
        const consentStatus = consent ? 'granted' : 'denied'

        setConsent({
            analytics_storage: consentStatus,
            ad_storage: consentStatus,
        })
    }, [consent])

    return (
        <AnalyticsProvider sdk={analytics}>
            <BrowserRouter>
                <Routes>
                    <Route path='/' element={<Layout />}>
                        <Route index element={<Home />} />
                        <Route path='about-us' element={<AboutUs />} />
                        <Route path='faq' element={<Faq />} />
                        <Route path='terms-and-conditions' element={<TermsAndConditions />} />
                        <Route path='cookie-policy' element={<CookiePolicy />} />
                        <Route path='privacy-policy' element={<PrivacyPolicy />} />
                        <Route path=':id' element={<Product />} />
                        <Route path='*' element={<NotFound />} />
                    </Route>
                </Routes>
                <PageView />
            </BrowserRouter>
        </AnalyticsProvider>
    )
}
