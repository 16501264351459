import { useI18nProvider } from './useI18nProvider'

export const useLocaleLabel = () => {
    const { locale: currentLocale, localeInfo } = useI18nProvider()

    for (const info of localeInfo) {
        const { locale, name } = info

        if (locale === currentLocale) {
            return `${name} (${locale.toUpperCase()})`
        }
    }

    return `Español (ES)`
}
