import React, { ReactNode } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { SxProps, styled } from '@mui/material'

const PREFIX = 'OrAnchor'

const classes = {
    link: `${PREFIX}-link`,
}

const StyledAnchor = styled('a')({
    [`&.${classes.link}`]: {
        color: 'rgb(34,34,34)',
    },
})

export interface AnchorProps {
    children?: ReactNode
    className?: string
    href: string
    onClick?: () => void
    sx?: SxProps
    target: string
    title: string
    underline?: boolean
    variant: string
}

export const Anchor = (props: AnchorProps) => {
    const {
        children,
        className,
        href,
        onClick,
        target,
        title,
        underline,
    } = props

    return (
        <StyledAnchor
            className={clsx(classes.link, className)}
            href={href}
            onClick={onClick}
            rel='noopener noreferrer'
            target={target}
            title={title}
            sx={{
                textDecoration: underline ? 'underline' : 'none',
            }}
        >
            {children}
        </StyledAnchor>
    )
}

Anchor.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    href: PropTypes.string,
    target: PropTypes.string,
    title: PropTypes.string,
    underline: PropTypes.bool,
    variant: PropTypes.string,
}

Anchor.defaultProps = {
    target: '_blank',
    underline: false,
    variant: 'body2',
}
