import { AnalyticsCallOptions } from '@firebase/analytics'
import { logEvent } from 'firebase/analytics'
import { useConfig } from '../config'
import { useAnalytics } from './useAnalytics'

export const useLogEvent = () => {
    const analytics = useAnalytics()
    const { debug } = useConfig()

    return (event: string, props?: { [key: string]: any }, options?: AnalyticsCallOptions): void => {
        logEvent(analytics, event, props, options)

        if (debug) {
            console.log(event, props, options)
        }
    }
}
