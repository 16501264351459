import PropTypes from 'prop-types'
import React from 'react'
import { styled } from '@mui/material'
import { useTranslate } from '../service'

const PREFIX = 'OrSkipToContent'

const classes = {
    link: `${PREFIX}-link`,
}

const StyledAnchor = styled('a')(({ theme }) => ({
    [`&.${classes.link}`]: {
        backgroundColor: 'white',
        border: '1px solid rgb(34, 34, 34)',
        borderRadius: theme.spacing(0.5),
        color: 'rgb(34,34,34)',
        fontSize: '18px',
        height: theme.spacing(8),
        lineHeight: theme.spacing(8),
        padding: theme.spacing(0, 2),
        position: 'absolute',
        textDecoration: 'none',
        transform: 'translateX(-200%)',
        transition: 'transform 0.3s',
    },
    [`&.${classes.link}:focus`]: {
        transform: 'translateX(4px)',
    },
}))

export interface SkipToContentProps {
    skipTo: string
}

export const SkipToContent = (props: SkipToContentProps) => {
    const { skipTo } = props
    const translate = useTranslate()

    return (
        <StyledAnchor
            className={classes.link}
            href={`#${skipTo}`}
        >
            {translate('app.links.skipToContent')}
        </StyledAnchor>
    )
}

SkipToContent.propTypes = {
    skipTo: PropTypes.string,
}

SkipToContent.defaultProps = {
    skipTo: 'main',
}
