import React from 'react'
import { Container, Typography, Divider, styled, useTheme } from '@mui/material'
import { Helmet } from 'react-helmet-async'
import { StyledSection, classes } from './Styled'
import { useConfig, useScrollToTop, useTranslate } from '../../service'

const StyledAnchor = styled('a')({
    color: 'rgb(34,34,34)',
    '&:hover': {
        fontWeight: 600,
    },
})

export const PrivacyPolicy = () => {
    const theme = useTheme()
    const translate = useTranslate()
    const { brand, contact, location, url } = useConfig()
    const { name } = brand
    const { emailAddress } = contact
    const { address, locality, postalCode, province } = location

    useScrollToTop()

    return (
        <>
            <Helmet prioritizeSeoTags>
                <title>Ocean Rider · {translate('app.links.privacy')}</title>
                <link rel='canonical' href='https://oceanridertenerife.es/privacy-policy' />
                <link rel='alternate' href='https://oceanridertenerife.es/privacy-policy?hl=en' hrefLang="en" />
            </Helmet>
            <StyledSection className={classes.root}>
                <Container maxWidth='md'>
                    <Typography className={classes.h1} component='h1'>
                        {translate('app.links.privacy')}
                    </Typography>
                    <Typography variant='body2'>
                        {translate('privacy.text.updated')}
                    </Typography>
                    <Typography variant='body1'>
                        {translate('privacy.text.intro.1', { company: name })}
                    </Typography>
                    <ul>
                        <li>
                            <Typography variant='body1'>
                                {translate('privacy.text.intro.2', { url })}
                            </Typography>
                        </li>
                        <li>
                            <Typography variant='body1'>
                                {translate('privacy.text.intro.3')}
                            </Typography>
                        </li>
                    </ul>
                    <Typography variant='body1'>
                        {translate('privacy.text.intro.4', { emailAddress })}
                    </Typography>
                    <Typography className={classes.h2} component='h2'>
                        {translate('privacy.title.summary')}
                    </Typography>
                    <Typography variant='body1'>
                        {translate('privacy.text.summary.1')}
                    </Typography>
                    <ul>
                        <li>
                            <Typography variant='body1'>
                                <b>{translate('privacy.text.bold.personalInfo')}</b>
                                {' '}
                                {translate('privacy.text.summary.2')}
                            </Typography>
                        </li>
                        <li>
                            <Typography variant='body1'>
                                <b>{translate('privacy.text.bold.sensitiveInfo')}</b>
                                {' '}
                                {translate('privacy.text.summary.3')}
                            </Typography>
                        </li>
                        <li>
                            <Typography variant='body1'>
                                <b>{translate('privacy.text.bold.thirdPartyInfo')}</b>
                                {' '}
                                {translate('privacy.text.summary.4')}
                            </Typography>
                        </li>
                        <li>
                            <Typography variant='body1'>
                                <b>{translate('privacy.text.bold.processInfo')}</b>
                                {' '}
                                {translate('privacy.text.summary.5')}
                                {' '}
                                <StyledAnchor href='#privacy-processing'>
                                    {translate('privacy.text.links.processInfo')}
                                </StyledAnchor>
                            </Typography>
                        </li>
                        <li>
                            <Typography variant='body1'>
                                <b>{translate('privacy.text.bold.shareInfo')}</b>
                                {' '}
                                {translate('privacy.text.summary.6')}
                            </Typography>
                        </li>
                        <li>
                            <Typography variant='body1'>
                                <b>{translate('privacy.text.bold.keepInfo')}</b>
                                {' '}
                                {translate('privacy.text.summary.7')}
                                {' '}
                                <StyledAnchor href='#privacy-kepping'>
                                    {translate('privacy.text.links.keepInfo')}
                                </StyledAnchor>
                            </Typography>
                        </li>
                        <li>
                            <Typography variant='body1'>
                                <b>{translate('privacy.text.bold.rightsInfo')}</b>
                                {' '}
                                {translate('privacy.text.summary.8')}
                                {' '}
                                <StyledAnchor href='#privacy-rights'>
                                    {translate('privacy.text.links.rightsInfo')}
                                </StyledAnchor>
                            </Typography>
                        </li>
                        <li>
                            <Typography variant='body1'>
                                <b>{translate('privacy.text.bold.exerciseRightsInfo')}</b>
                                {' '}
                                {translate('privacy.text.summary.9')}
                            </Typography>
                        </li>
                    </ul>
                    <Typography variant='body1'>
                        <b>{translate('privacy.text.bold.moreInfo')}</b>
                        {' '}
                        {translate('privacy.text.summary.10')}
                    </Typography>

                    <Divider sx={{ margin: theme.spacing(4, 0) }} />

                    <Typography component='h2' id='privacy-toc' variant='h2'>
                        {translate('privacy.title.toc')}
                    </Typography>
                    <ol>
                        <li>
                            <StyledAnchor href='#privacy-collection'>
                                <Typography variant='body1'>
                                    {translate('privacy.title.collection')}
                                </Typography>
                            </StyledAnchor>
                        </li>
                        <li>
                            <StyledAnchor href='#privacy-processing'>
                                <Typography variant='body1'>
                                    {translate('privacy.title.processing')}
                                </Typography>
                            </StyledAnchor>
                        </li>
                        <li>
                            <StyledAnchor href='#privacy-legal-basis'>
                                <Typography variant='body1'>
                                    {translate('privacy.title.legalBasis')}
                                </Typography>
                            </StyledAnchor>
                        </li>
                        <li>
                            <StyledAnchor href='#privacy-sharing'>
                                <Typography variant='body1'>
                                    {translate('privacy.title.sharing')}
                                </Typography>
                            </StyledAnchor>
                        </li>
                        <li>
                            <StyledAnchor href='#privacy-tracking'>
                                <Typography variant='body1'>
                                    {translate('privacy.title.tracking')}
                                </Typography>
                            </StyledAnchor>
                        </li>
                        <li>
                            <StyledAnchor href='#privacy-keeping'>
                                <Typography variant='body1'>
                                    {translate('privacy.title.keeping')}
                                </Typography>
                            </StyledAnchor>
                        </li>
                        <li>
                            <StyledAnchor href='#privacy-rights'>
                                <Typography variant='body1'>
                                    {translate('privacy.title.rights')}
                                </Typography>
                            </StyledAnchor>
                        </li>
                        <li>
                            <StyledAnchor href='#privacy-access'>
                                <Typography variant='body1'>
                                    {translate('privacy.title.access')}
                                </Typography>
                            </StyledAnchor>
                        </li>
                        <li>
                            <StyledAnchor href='#privacy-contact'>
                                <Typography variant='body1'>
                                    {translate('privacy.title.contact')}
                                </Typography>
                            </StyledAnchor>
                        </li>
                        <li>
                            <StyledAnchor href='#privacy-updates'>
                                <Typography variant='body1'>
                                    {translate('privacy.title.updates')}
                                </Typography>
                            </StyledAnchor>
                        </li>
                    </ol>

                    <Divider sx={{ margin: theme.spacing(4, 0) }} />

                    <Typography component='h2' id='privacy-collection' variant='h2'>
                        {'1. '}
                        {translate('privacy.title.collection')}
                    </Typography>
                    <Typography component='h3' variant='h3' sx={{ fontSize: '20px', margin: theme.spacing(3, 0, 2) }}>
                        {'a. '}
                        {translate('privacy.title.personal')}
                    </Typography>
                    <Typography variant='body1'>
                        <b>{translate('privacy.text.bold.short')}</b>
                        {': '}
                        <i>{translate('privacy.text.collection.1')}</i>
                    </Typography>
                    <Typography variant='body1'>
                        {translate('privacy.text.collection.2')}
                    </Typography>
                    <Typography component='h3' variant='h3' sx={{ fontSize: '20px', margin: theme.spacing(3, 0, 2) }}>
                        {'b. '}
                        {translate('privacy.title.sensitive')}
                    </Typography>
                    <Typography variant='body1'>
                        <b>{translate('privacy.text.bold.short')}</b>
                        {': '}
                        <i>{translate('privacy.text.collection.3')}</i>
                    </Typography>
                    <Typography component='h3' variant='h3' sx={{ fontSize: '20px', margin: theme.spacing(3, 0, 2) }}>
                        {'c. '}
                        {translate('privacy.title.automatic')}
                    </Typography>
                    <Typography variant='body1'>
                        <b>{translate('privacy.text.bold.short')}</b>
                        {': '}
                        <i>{translate('privacy.text.collection.4')}</i>
                    </Typography>
                    <Typography variant='body1'>
                        {translate('privacy.text.collection.5')}
                    </Typography>
                    <Typography variant='body1'>
                        {translate('privacy.text.collection.6')}
                        {' '}
                        <StyledAnchor href='privacy-policy'>
                            {translate('app.links.privacy')}
                        </StyledAnchor>
                        {'. '}
                        {translate('privacy.text.collection.6')}
                        {'.'}
                    </Typography>
                    <Typography variant='body1'>
                        {translate('privacy.text.collection.7')}
                    </Typography>
                    <ul>
                        <li>
                            <Typography variant='body1'>
                                <b>{translate('privacy.text.data.usage')}</b>
                                {'. '}
                                {translate('privacy.text.collection.8')}
                            </Typography>
                        </li>
                        <li>
                            <Typography variant='body1'>
                                <b>{translate('privacy.text.data.device')}</b>
                                {'. '}
                                {translate('privacy.text.collection.9')}
                            </Typography>
                        </li>
                        <li>
                            <Typography variant='body1'>
                                <b>{translate('privacy.text.data.location')}</b>
                                {'. '}
                                {translate('privacy.text.collection.10')}
                            </Typography>
                        </li>
                    </ul>

                    <Typography component='h2' id='privacy-processing' variant='h2' sx={{ margin: theme.spacing(5, 0, 2) }}>
                        {'2. '}
                        {translate('privacy.title.processing')}
                    </Typography>
                    <Typography variant='body1'>
                        {translate('privacy.text.processing.1')}
                    </Typography>

                    <Typography component='h2' id='privacy-legalBasis' variant='h2' sx={{ margin: theme.spacing(5, 0, 2) }}>
                        {'3. '}
                        {translate('privacy.title.legalBasis')}
                    </Typography>
                    <Typography variant='body1'>
                        <b>{translate('privacy.text.bold.short')}</b>
                        {': '}
                        <i>{translate('privacy.text.legalBasis.1')}</i>
                    </Typography>
                    <Typography variant='body1'>
                        {translate('privacy.text.legalBasis.2')}
                    </Typography>
                    <ul>
                        <li>
                            <Typography variant='body1'>
                                <b>{translate('privacy.text.bold.consent')}</b>
                                {'. '}
                                {translate('privacy.text.legalBasis.3')}
                            </Typography>
                        </li>
                        <li>
                            <Typography variant='body1'>
                                <b>{translate('privacy.text.bold.legal')}</b>
                                {'. '}
                                {translate('privacy.text.legalBasis.4')}
                            </Typography>
                        </li>
                    </ul>

                    <Typography component='h2' id='privacy-sharing' variant='h2' sx={{ margin: theme.spacing(5, 0, 2) }}>
                        {'4. '}
                        {translate('privacy.title.sharing')}
                    </Typography>
                    <Typography variant='body1'>
                        <b>{translate('privacy.text.bold.short')}</b>
                        {': '}
                        <i>{translate('privacy.text.sharing.1')}</i>
                    </Typography>
                    <Typography variant='body1'>
                        {translate('privacy.text.sharing.2')}
                    </Typography>
                    <ul>
                        <li>
                            <Typography variant='body1'>
                                <b>{translate('privacy.text.bold.analytics')}</b>
                                {'. Google Analytics for Firebase'}
                            </Typography>
                        </li>
                    </ul>

                    <Typography component='h2' id='privacy-tracking' variant='h2' sx={{ margin: theme.spacing(5, 0, 2) }}>
                        {'5. '}
                        {translate('privacy.title.tracking')}
                    </Typography>
                    <Typography variant='body1'>
                        <b>{translate('privacy.text.bold.short')}</b>
                        {': '}
                        <i>{translate('privacy.text.tracking.1')}</i>
                    </Typography>
                    <Typography variant='body1'>
                        {translate('privacy.text.tracking.2')}
                        {' '}
                        <StyledAnchor href='cookie-policy'>
                            {translate('app.links.cookies')}
                        </StyledAnchor>
                    </Typography>

                    <Typography component='h2' id='privacy-keeping' variant='h2' sx={{ margin: theme.spacing(5, 0, 2) }}>
                        {'6. '}
                        {translate('privacy.title.keeping')}
                    </Typography>
                    <Typography variant='body1'>
                        <b>{translate('privacy.text.bold.short')}</b>
                        {': '}
                        <i>{translate('privacy.text.keeping.1')}</i>
                    </Typography>
                    <Typography variant='body1'>
                        {translate('privacy.text.keeping.2')}
                    </Typography>
                    <Typography variant='body1'>
                        {translate('privacy.text.keeping.3')}
                    </Typography>

                    <Typography component='h2' id='privacy-rights' variant='h2' sx={{ margin: theme.spacing(5, 0, 2) }}>
                        {'7. '}
                        {translate('privacy.title.rights')}
                    </Typography>
                    <Typography variant='body1'>
                        <b>{translate('privacy.text.bold.short')}</b>
                        {': '}
                        <i>{translate('privacy.text.rights.1')}</i>
                    </Typography>
                    <Typography variant='body1'>
                        {translate('privacy.text.rights.2', { company: name })}
                    </Typography>
                    <ul>
                        <li>
                            <Typography variant='body1'>
                                <b>{translate('privacy.text.bold.access')}</b>
                            </Typography>
                        </li>
                        <li>
                            <Typography variant='body1'>
                                <b>{translate('privacy.text.bold.modification')}</b>
                            </Typography>
                        </li>
                        <li>
                            <Typography variant='body1'>
                                <b>{translate('privacy.text.bold.removal')}</b>
                                {'. '}
                                {translate('privacy.text.rights.3')}
                            </Typography>
                        </li>
                        <li>
                            <Typography variant='body1'>
                                <b>{translate('privacy.text.bold.oppose')}</b>
                            </Typography>
                        </li>
                        <li>
                            <Typography variant='body1'>
                                <b>{translate('privacy.text.bold.limit')}</b>
                            </Typography>
                        </li>
                        <li>
                            <Typography variant='body1'>
                                <b>{translate('privacy.text.bold.portability')}</b>
                            </Typography>
                        </li>
                        <li>
                            <Typography variant='body1'>
                                <b>{translate('privacy.text.bold.automation')}</b>
                            </Typography>
                        </li>
                        <li>
                            <Typography variant='body1'>
                                <b>{translate('privacy.text.bold.revoke')}</b>
                                {'. '}
                                {translate('privacy.text.rights.4')}
                                {' '}
                                <StyledAnchor href='/cookie-policy'>
                                    {translate('app.links.cookies')}
                                </StyledAnchor>
                            </Typography>
                        </li>
                    </ul>
                    <Typography variant='body1'>
                        {translate('privacy.text.rights.5')}
                        {' '}
                        <StyledAnchor href='https://www.aepd.es/' rel='noopener noreferrer' target='_blank'>
                            www.aepd.es
                        </StyledAnchor>
                        {'.'}
                    </Typography>

                    <Typography component='h2' id='privacy-access' variant='h2' sx={{ margin: theme.spacing(5, 0, 2) }}>
                        {'8. '}
                        {translate('privacy.title.access')}
                    </Typography>
                    <Typography variant='body1'>
                        {translate('privacy.text.access.1')}
                        {' '}
                        <StyledAnchor href='#privacy-contact'>
                            {translate('privacy.title.contact')}
                        </StyledAnchor>
                        {'. '}
                        {translate('privacy.text.access.2')}
                    </Typography>

                    <Typography component='h2' id='privacy-contact' variant='h2' sx={{ margin: theme.spacing(5, 0, 2) }}>
                        {'9. '}
                        {translate('privacy.title.contact')}
                    </Typography>
                    <Typography variant='body1'>
                        {translate('privacy.text.contact.1', { emailAddress })}
                    </Typography>
                    <Typography variant='body1' sx={{ margin: theme.spacing(3, 0, 0, 4) }}>
                        {name.toUpperCase()}<br/>
                        {`${address}`}<br/>
                        {`${postalCode} ${locality}`}<br/>
                        {province}
                    </Typography>

                    <Typography component='h2' id='privacy-updates' variant='h2' sx={{ margin: theme.spacing(5, 0, 2) }}>
                        {'10. '}
                        {translate('privacy.title.updates')}
                    </Typography>
                    <Typography variant='body1'>
                        <b>{translate('privacy.text.bold.short')}</b>
                        {': '}
                        <i>{translate('privacy.text.updates.1')}</i>
                    </Typography>
                    <Typography variant='body1'>
                        {translate('privacy.text.updates.2')}
                    </Typography>
                </Container>
            </StyledSection>
        </>
    )
}
