import PropTypes from 'prop-types'
import React from 'react'
import TimeIcon from '@mui/icons-material/AccessTime'
import AgeIcon from '@mui/icons-material/EscalatorWarning'
import WeatherIcon from '@mui/icons-material/Waves'
import { ProductConfig, useCurrency, useTranslate } from '../../service'
import { Spacer } from '../Spacer'
import { Stack, Typography, styled, useTheme } from '@mui/material'

const PREFIX = 'OrConditions'

const classes = {
    row: `${PREFIX}-row`,
    col: `${PREFIX}-col`,
    icon: `${PREFIX}-icon`,
}

const StyledList = styled('ul')(({ theme }) => ({
    [`&.${classes.row}`]: {
        alignItems: 'flex-start',
        display: 'flex',
        gap: theme.spacing(2),
        justifyContent: 'flex-start',
        listStyle: 'none',
        margin: theme.spacing(0, 0, 6),
        padding: 0,
        width: '100%',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },
    },
    [`& .${classes.col}`]: {
        display: 'flex',
        flex: 1,
        flexBasis: '100%',
        flexDirection: 'column',
        listStyle: 'none',
        width: '33%',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },
    [`& .${classes.icon}`]: {
        marginBottom: theme.spacing(2),
    },
}))

export interface ProductConditionsProps {
    product: ProductConfig
}

export const ProductConditions = (props: ProductConditionsProps) => {
    const { product } = props
    const { harborFees, minAge } = product

    const currency = useCurrency()
    const theme = useTheme()
    const translate = useTranslate()

    return (
        <>
            <Spacer />
            <div>
                <Typography component='h2' variant='h2'>
                    {translate(`product.conditions.policy`)}
                </Typography>
                <StyledList className={classes.row}>
                    <li className={classes.col}>
                        <Stack direction='row'>
                            <TimeIcon className={classes.icon} sx={{ marginRight: theme.spacing(1) }} />
                            <Typography gutterBottom variant='highlight1'>
                                {translate(`product.conditions.checkIn`)}
                            </Typography>
                        </Stack>
                        <Stack>
                            <Typography variant='body2'>
                                {translate(`product.conditions.checkInTime`)}
                            </Typography>
                            <Typography variant='body2'>
                                {
                                    translate(`product.conditions.harborFees`, {
                                        price: currency(harborFees)
                                    })
                                }
                            </Typography>
                        </Stack>
                    </li>
                    <li className={classes.col}>
                        <Stack direction='row'>
                            <AgeIcon className={classes.icon} sx={{ marginRight: theme.spacing(1) }} />
                            <Typography gutterBottom variant='highlight1'>
                                {translate(`product.conditions.age`)}
                            </Typography>
                        </Stack>
                        <Stack>
                            <Typography variant='body2'>
                                {translate(`product.conditions.minAge`, { minAge })}
                            </Typography>
                            <Typography variant='body2'>
                                {translate(`product.conditions.kids`, { minAge })}
                            </Typography>
                        </Stack>
                    </li>
                    <li className={classes.col}>
                        <Stack direction='row'>
                            <WeatherIcon className={classes.icon} sx={{ marginRight: theme.spacing(1) }} />
                            <Typography gutterBottom variant='highlight1'>
                                {translate(`product.conditions.weather`)}
                            </Typography>
                        </Stack>
                        <Stack>
                            <Typography variant='body2'>
                                {translate(`product.conditions.onlyIfFavorableWeather`)}
                            </Typography>
                        </Stack>
                    </li>
                </StyledList>
            </div>
        </>
    )
}

ProductConditions.propTypes = {
    product: PropTypes.object,
}
