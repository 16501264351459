import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import CloseIcon from '@mui/icons-material/Close'
import DurationIcon from '@mui/icons-material/AccessTime'
import LaunchIcon from '@mui/icons-material/Launch'
import PhoneIcon from '@mui/icons-material/Phone'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import clsx from 'clsx'
import {
    BOOK,
    CHANGE_DURATION,
    DurationConfig,
    ProductConfig,
    getCurrentFee,
    getCurrentTicketUrl,
    useConfig,
    useCurrency,
    useLocale,
    useLogEvent,
    useTranslate,
} from '../../service'
import {
    Button,
    Container,
    Divider,
    Drawer,
    Fab,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Tooltip,
    Typography,
    styled,
    useTheme,
    useMediaQuery,
} from '@mui/material'

const PREFIX = 'OrBooking'

const BookingClass = {
    root: `${PREFIX}-root`,
    wrap: `${PREFIX}-wrap`,
    left: `${PREFIX}-left`,
    right: `${PREFIX}-right`,
    button: `${PREFIX}-button`,
    whatsButton: `${PREFIX}-wbutton`,
    drawerButton: `${PREFIX}-drawer-button`,
    drawerButtonIcon: `${PREFIX}-drawer-button-icon`,
    drawerButtonText: `${PREFIX}-drawer-button-text`,
    drawerPaper: `${PREFIX}-drawer-paper`,
    drawerContainer: `${PREFIX}-button`,
    drawerWrap: `${PREFIX}-drawer-wrap`,
    drawerTitle: `${PREFIX}-drawer-title`,
    drawerClose: `${PREFIX}-drawer-close`,
    drawerDivider: `${PREFIX}-drawer-divider`,
    drawerItem: `${PREFIX}-drawer-item`,
}

const StyledRoot = styled('div')(({ theme }) => ({
    [`&.${BookingClass.root}`]: {
        backgroundColor: 'white',
        borderTop: '1px solid rgb(221, 221, 221)',
        bottom: 0,
        position: 'fixed',
        width: '100%',
        zIndex: 2,
    },
    [`& .${BookingClass.wrap}`]: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: theme.spacing(2, 0),
    },
    [`& .${BookingClass.left}`]: {
        alignItems: 'flex-start',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
    [`& .${BookingClass.right}`]: {
        display: 'block',
        flexShrink: 2,
        marginLeft: 'auto',
    },
    [`& .${BookingClass.button}`]: {
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '20px',
        marginLeft: theme.spacing(2),
        padding: theme.spacing(1.75, 2),
        textTransform: 'none',
    },
    [`& .${BookingClass.drawerButton}`]: {
        borderColor: 'rgb(247, 247, 247)',
        padding: theme.spacing(0.75, 2),
        textTransform: 'none',
        [`&:focus, &:hover, &:active`]: {
            borderColor: 'rgb(34, 34, 34)',
        },
    },
    [`& .${BookingClass.drawerButton} span`]: {
        textAlign: 'left',
    },
    [`& .${BookingClass.drawerButton} p`]: {
        display: 'block',
        margin: 0,
        textTransform: 'none',
    },
    [`& .${BookingClass.drawerButtonIcon}`]: {
        color: 'rgb(34, 34, 34)',
        marginLeft: theme.spacing(1),
    },
    [`& .${BookingClass.drawerButtonText}`]: {
        lineHeight: 1,
    },
    [`& .${BookingClass.whatsButton}`]: {
        color: 'white',
        backgroundColor: '#25d366',
    },
}))

const StyledDrawer = styled(Drawer)(({ theme }) => ({
    [`& .${BookingClass.drawerPaper}`]: {
        borderRadius: theme.spacing(1.5, 1.5, 0, 0),
        margin: '0 auto',
        maxWidth: '900px',
    },
    [`& .${BookingClass.drawerContainer}`]: {
        margin: theme.spacing(3, 0),
    },
    [`& .${BookingClass.drawerWrap}`]: {
        alignItems: 'center',
        display: 'flex',
        margin: theme.spacing(0, 0, 2),
    },
    [`& .${BookingClass.drawerTitle}`]: {
        alignItems: 'center',
        color: 'rgb(34,34,34)',
        display: 'flex',
        fontSize: '18px',
        fontWeight: 600,
        gap: theme.spacing(1),
        lineHeight: '22px',
        margin: theme.spacing(2, 0),
    },
    [`& .${BookingClass.drawerClose}`]: {
        marginLeft: 'auto',
    },
    [`& .${BookingClass.drawerDivider}`]: {
        marginBottom: theme.spacing(1),
    },
    [`& .${BookingClass.drawerItem}`]: {
        border: '1px solid transparent',
        borderRadius: theme.spacing(1),
        cursor: 'pointer',
        [`&:focus, &:hover, &:active`]: {
            backgroundColor: 'rgb(247, 247, 247)',
        },
        [`&.selected`]: {
            borderColor: 'rgb(34, 34, 34)',
        },
    },
}))

export interface ProductBookingProps {
    displayPhoneOnDesktop?: boolean
    product: ProductConfig
    onChange?: (index: number) => void
    selectOnClick?: boolean
}

export const ProductBooking = (props: ProductBookingProps) => {
    const { displayPhoneOnDesktop, product, onChange, selectOnClick } = props
    const { id, durations, residentDiscount } = product

    const isSingleDuration = durations.length === 1
    const initialDuration = durations[0].maxDuration

    const currency = useCurrency()
    const locale = useLocale()
    const logEvent = useLogEvent()
    const translate = useTranslate()
    const theme = useTheme()
    const isSmall = useMediaQuery((theme.breakpoints.down('sm')))
    const isLarge = useMediaQuery((theme.breakpoints.up('lg')))
    const { contact: { phone }} = useConfig()

    const [open, setOpen] = useState<boolean>(false)
    const [value, setValue] = useState<number>(initialDuration)

    const { resident } = getCurrentFee(value, durations)
    const ticketUrl = getCurrentTicketUrl(value, durations)
    const tel = `tel:${phone.replace(/\D/g, '')}`
    const whats = `https://wa.me/${phone.replace(/\D/g, '')}`
    const marginLeft = isSmall ? '8px' : '16px'

    const handleBook = (id: string, channel: string) =>
        () => {
            logEvent(BOOK, {
                channel,
                product: id,
                where: 'detail',
            })
        }

    const handleDuration = (newDuration: number, newIndex: number) =>
        () => {
            setValue(newDuration)
            
            logEvent(CHANGE_DURATION, {
                from: value,
                to: newDuration,
            })

            if (onChange) {
                onChange(newIndex)
            }

            if (selectOnClick) {
                toggleDrawer()
            }
        }

    const toggleDrawer = () => {
        setOpen(open => ! open)
    }

    const renderFees = () => (
        <>
            <Typography
                className={BookingClass.drawerButtonText}
                component='p'
                variant='highlight1'
            >
                <span>
                    {
                        translate('product.details.since')
                    }
                </span>
                <span>
                    {' '}
                </span>
                <span>
                    {
                        currency(resident)
                    }
                </span>
            </Typography>
            <Typography
                component='p'
                variant='body2'
            >
                {
                    translate('product.details.duration', {
                        duration: value,
                    })
                }
            </Typography>
        </>
    )

    return (
        <>
            <StyledRoot className={BookingClass.root}>
                <Container maxWidth='md'>
                    <div className={BookingClass.wrap}>
                        <div className={BookingClass.left}>
                            <span>&nbsp;</span>
                            {/*
                            {
                                isSingleDuration ? renderFees() : (
                                    <Button
                                        className={BookingClass.drawerButton}
                                        onClick={toggleDrawer}
                                        variant='outlined'
                                    >
                                        <span>
                                            {
                                                renderFees()
                                            }
                                        </span>
                                        <ArrowDropDownIcon className={BookingClass.drawerButtonIcon} />
                                    </Button>
                                )
                            }
                            */}
                        </div>
                        <div className={BookingClass.right}>
                            {
                                ticketUrl ? (
                                    isSmall ? (
                                        <Fab
                                            aria-label={translate('product.booking.buy')}
                                            color='primary'
                                            href={ticketUrl}
                                            onClick={handleBook(id, 'bonosvip')}
                                            size='medium'
                                            sx={{ marginLeft }}
                                        >
                                            <LaunchIcon />
                                        </Fab>
                                    ) : (
                                        <Button
                                            className={BookingClass.button}
                                            disableElevation
                                            href={ticketUrl}
                                            onClick={handleBook(id, 'bonosvip')}
                                            rel='noopener noreferrer'
                                            target='_blank'
                                            variant='contained'
                                        >
                                            {
                                                translate('product.booking.buy')
                                            }
                                            {
                                                isLarge ? (
                                                    <LaunchIcon
                                                        fontSize='small'
                                                        sx={{ marginLeft }}
                                                    />
                                                ) : null
                                            }
                                        </Button>
                                    )
                                ) : null
                            }
                            {
                                isSmall ? (
                                    <Fab
                                        aria-label={translate('product.booking.action', { phone })}
                                        color='primary'
                                        href={tel}
                                        onClick={handleBook(id, 'phone')}
                                        size='medium'
                                        sx={{ marginLeft }}
                                    >
                                        <PhoneIcon />
                                    </Fab>
                                ) : (
                                    <Button
                                        className={BookingClass.button}
                                        disableElevation
                                        href={tel}
                                        onClick={handleBook(id, 'phone')}
                                        variant='contained'
                                    >
                                        {
                                            displayPhoneOnDesktop ? (
                                                <>        
                                                    <PhoneIcon fontSize='small' sx={{ mr: 1 }} />
                                                    { phone }
                                                </>
                                            ) : translate('product.booking.book')
                                        }
                                    </Button>
                                )
                            }
                            <Fab
                                aria-label={translate('product.booking.whatsapp')}
                                className={BookingClass.whatsButton}
                                href={whats}
                                onClick={handleBook(id, 'whatsapp')}
                                size='medium'
                                sx={{ marginLeft }}
                            >
                                <WhatsAppIcon />
                            </Fab>
                        </div>
                    </div>
                </Container>
            </StyledRoot>
            {
                isSingleDuration ? null : (
                    <StyledDrawer
                        anchor='bottom'
                        classes={{ paper: BookingClass.drawerPaper }}
                        open={open}
                        onClose={toggleDrawer}
                    >
                        <Container
                            className={BookingClass.drawerContainer}
                            maxWidth='md'
                        >
                            <div className={BookingClass.drawerWrap}>
                                <Typography className={BookingClass.drawerTitle}>
                                    <DurationIcon />
                                    {translate('app.title.selectDuration')}
                                </Typography>
                                <Tooltip title={translate('app.title.close')}>
                                    <IconButton className={BookingClass.drawerClose} onClick={toggleDrawer}>
                                        <CloseIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <Divider className={BookingClass.drawerDivider} />
                            <List>
                                {
                                    durations.map((duration: DurationConfig, i: number) => {
                                        const { maxDuration, fees } = duration
                                        const { resident, ticketUrl } = fees

                                        return (
                                            <ListItem
                                                className={clsx(BookingClass.drawerItem, {
                                                    selected: value === maxDuration
                                                })}
                                                key={`duration-${maxDuration}-${i}`}
                                                onClick={handleDuration(maxDuration, i)}
                                            >
                                                <ListItemText
                                                    primary={translate('product.details.duration', { duration: maxDuration })}
                                                    secondary={
                                                        <Typography variant='body2'>
                                                            <span>
                                                                {
                                                                    translate('product.details.since')
                                                                }
                                                            </span>
                                                            <span>
                                                                {' '}
                                                            </span>
                                                            <span>
                                                                {
                                                                    currency(resident)
                                                                }
                                                            </span>
                                                            {
                                                                residentDiscount ? (
                                                                    <span>
                                                                        {' ('}
                                                                        {
                                                                            translate('product.details.nonResident', { 
                                                                                amount: currency(resident + residentDiscount)
                                                                            })
                                                                        }
                                                                        {')'}
                                                                    </span>
                                                                ) : null
                                                            }
                                                            {
                                                                ticketUrl ? (
                                                                    <span>
                                                                        {' ('}
                                                                        {
                                                                            translate('product.details.purchaseOnline')
                                                                        }
                                                                        {')'}
                                                                    </span>
                                                                ) : null
                                                            }
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                        )
                                    })
                                }
                            </List>
                        </Container>
                    </StyledDrawer>
                )
            }
        </>
    )
}

ProductBooking.propTypes = {
    product: PropTypes.object,
}
