import { ProductConfig } from './types'
import { useConfig } from './useConfig'
import { useParams } from 'react-router-dom'

export const useProduct = (): ProductConfig | undefined => {
    const { products } = useConfig()
    const { id } = useParams()

    if (id) {
        for (const product of products) {
            if (id === product.id) {
                return product
            }
        }
    }

    return undefined
}
