import ExtraBriefingIcon from '@mui/icons-material/Anchor'
import ExtraCancellationIcon from '@mui/icons-material/EventBusy'
import ExtraInsuranceIcon from '@mui/icons-material/Healing'
import ExtraLifejacketIcon from '@mui/icons-material/Support'
import ExtraLockerRoomIcon from '@mui/icons-material/DryCleaning'
import ExtraPhotosAndVideosIcon from '@mui/icons-material/PhotoCamera'
import ExtraSwimIcon from '@mui/icons-material/Pool'
import PropTypes from 'prop-types'
import React from 'react'
import { ProductConfig, useCurrency, useTranslate } from '../../service'
import { List, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Link } from '../Link'
import { ProductExtra } from './ProductExtra'
import { Spacer } from '../Spacer'

export interface ProductExtrasProps {
    product: ProductConfig
}

export const ProductExtras = (props: ProductExtrasProps) => {
    const { product } = props
    const {
        includesSwim,
        includesPhotosAndVideos,
        includesBriefing,
        includesLifejacket,
        includesLockerRoom,
        includesInsurance,
        cancellableUntil,
        photoAndVideo,
    } = product

    const currency = useCurrency()
    const translate = useTranslate()
    const theme = useTheme()
    const isSmall = useMediaQuery(theme.breakpoints.down('md'))

    return (
        <>
            <Spacer />
            <Typography component='h2' variant='h2'>
                {translate(`product.extra.title`)}
            </Typography>
            <List dense={isSmall}>
                {
                    includesSwim ? (
                        <ProductExtra
                            icon={<ExtraSwimIcon sx={{ color: 'rgb(34,34,34)' }} />}
                            primaryText={translate('product.extra.includesSwim')}
                            secondaryText={translate('product.help.includesSwim')}
                        />
                    ) : null
                }
                {
                    includesPhotosAndVideos ? (
                        <ProductExtra
                            icon={<ExtraPhotosAndVideosIcon sx={{ color: 'rgb(34,34,34)' }} />}
                            primaryText={translate('product.extra.includesPhotosAndVideos')}
                            secondaryText={translate('product.help.includesPhotosAndVideos', {
                                price: currency(photoAndVideo as number)
                            })}
                        />
                    ) : null
                }
                {
                    includesBriefing ? (
                        <ProductExtra
                            icon={<ExtraBriefingIcon sx={{ color: 'rgb(34,34,34)' }} />}
                            primaryText={translate('product.extra.includesBriefing')}
                            secondaryText={translate('product.help.includesBriefing')}
                        />
                    ) : null
                }
                {
                    includesLifejacket ? (
                        <ProductExtra
                            icon={<ExtraLifejacketIcon sx={{ color: 'rgb(34,34,34)' }} />}
                            primaryText={translate('product.extra.includesLifejacket')}
                            secondaryText={translate('product.help.includesLifejacket')}
                        />
                    ) : null
                }
                {
                    includesLockerRoom ? (
                        <ProductExtra
                            icon={<ExtraLockerRoomIcon sx={{ color: 'rgb(34,34,34)' }} />}
                            primaryText={translate('product.extra.includesLockerRoom')}
                            secondaryText={translate('product.help.includesLockerRoom')}
                        />
                    ) : null
                }
                {
                    includesInsurance ? (
                        <ProductExtra
                            icon={<ExtraInsuranceIcon sx={{ color: 'rgb(34,34,34)' }} />}
                            primaryText={translate('product.extra.includesInsurance')}
                        />
                    ) : null
                }
                {
                    cancellableUntil > 0 ? (
                        <ProductExtra
                            icon={<ExtraCancellationIcon sx={{ color: 'rgb(34,34,34)' }} />}
                            primaryText={translate('product.extra.cancellableUntil', { cancellableUntil })}
                        />
                    ) : null
                }
            </List>
            <Link to={'/terms-and-conditions'} underline>
                <Typography variant='highlight2'>
                    {translate('product.conditions.fullText')}
                </Typography>
            </Link>
        </>
    )
}

ProductExtras.propTypes = {
    product: PropTypes.object,
}
