import React from 'react'
import { FirebaseAppProvider } from 'reactfire'
import { ConfigContextProvider, I18nContextProvider, firebaseConfig, CookieContextProvider } from '../service'
import { HelmetProvider } from 'react-helmet-async'
import { ConsoleBanner } from './ConsoleBanner'
import { CookieConsent } from './CookieConsent'
import { Router } from './Router'
import { ThemeProvider } from '@mui/material'
import { theme } from './theme'
import 'leaflet/dist/leaflet.css'

export const App = () => (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
        <ConfigContextProvider>
            <I18nContextProvider>
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin='true' />
                <link href="https://fonts.googleapis.com/css2?family=Inter:wght@200;400;600&display=swap" rel="stylesheet" />
                <ThemeProvider theme={theme}>
                    <CookieContextProvider>
                        <HelmetProvider>
                            <Router />
                            <ConsoleBanner />
                            <CookieConsent />
                        </HelmetProvider>
                    </CookieContextProvider>
                </ThemeProvider>
            </I18nContextProvider>
        </ConfigContextProvider>
    </FirebaseAppProvider>
)
