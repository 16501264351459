import locales from './locales'
import { Cookies, LOCALE_COOKIE, useCookies } from '../cookie'
import { I18nContext } from './I18nContext'
import { I18nProvider, LOCALE_PARAM, LocaleInfo, TranslationDictionary } from './types'
import { ReactNode, useCallback, useMemo, useState } from 'react'
import { createTranslate } from './createTranslate'

const DEFAULT_LOCALE = 'es'

// Try to detect whether the browser locale matches a supported
// locale.
const getBrowserLocale = (): string | undefined => {
    const browserLocale = (navigator.languages || [navigator.language || (navigator as any).userLanguage])
        .map(locale => locale.trim().split(/[-_]/)[0])[0]

    if (browserLocale in locales) {
        return browserLocale
    }

    return undefined
}

// Try to detect the locale from the preferences stored in a cookie.
const getCookieLocale = (cookies: Cookies): string | undefined => {
    if (! cookies.enabled) {
        return undefined
    }

    const locale = cookies.get(LOCALE_COOKIE)

    if (locale in locales) {
        return locale
    }

    return undefined
}

// Try to detect the locale from the location
const getLocationLocale = (): string | undefined => {
    const query = new URLSearchParams(window.location.search)

    if (! query.has(LOCALE_PARAM)) {
        return undefined
    }

    const locale = query.get(LOCALE_PARAM) || ''

    if (! (locale in locales)) {
        return undefined
    }

    return locale
}

const getDictionary = (locale: string): TranslationDictionary => {
    const map = locales[locale]

    if (! map) {
        throw new Error(`Locale "${locale}" is not available`)
    }

    return map.messages
}
const getLocaleInfo = (): LocaleInfo[] => {
    const info = []

    for (const locale in locales) {
        const { name } = locales[locale]

        info.push({ locale, name })
    }

    return info
}

export interface I18nContextProviderProps {
    children: ReactNode
}

export const I18nContextProvider = (props: I18nContextProviderProps) => {
    const { children } = props
    const cookies = useCookies()

    const locale = getLocationLocale() || getCookieLocale(cookies) || getBrowserLocale() || DEFAULT_LOCALE
    const localeInfo = getLocaleInfo()
    const translate = createTranslate(locale, getDictionary(locale))

    const [state, setState] = useState<I18nProvider>({
        locale,
        localeInfo,
        translate,
        setLocale: () => {},
    })

    const setLocale = useCallback((newLocale: string): void => {
        setState({
            ...state,
            locale: newLocale,
            translate: createTranslate(newLocale, getDictionary(newLocale)),
        })
    }, [state, setState])

    const value: I18nProvider = useMemo(
        () => ({ ...state, setLocale }),
        [state, setLocale]
    )

    return (
        <I18nContext.Provider value={value}>
            {children}
        </I18nContext.Provider>
    )
}
