import InstructorIcon from '@mui/icons-material/Hail'
import PassengerIcon from '@mui/icons-material/EmojiPeople'
import PropTypes from 'prop-types'
import React from 'react'
import clsx from 'clsx'
import { ProductConfig, useTranslate } from '../../service'
import { SimpleSkiJetIcon } from '../icon'
import { Spacer } from '../Spacer'
import { Typography, styled, useMediaQuery, useTheme } from '@mui/material'

const PREFIX = 'OrAsset'

const classes = {
    root: `${PREFIX}-root`,
    item: `${PREFIX}-item`,
    inner: `${PREFIX}-inner`,
    icon: `${PREFIX}-icon`,
    jetSkiIcon: `${PREFIX}-jetSkiIcon`,
}

const StyledItem = styled('div')(({ theme }) => ({
    [`&.${classes.root}`]: {
        alignItems: 'flex-start',
        display: 'flex',
        gap: theme.spacing(2),
        justifyContent: 'flex-start',
        margin: theme.spacing(3, 0),
        width: '100%',
    },
    [`& .${classes.item}`]: {
        display: 'flex',
        flex: 1,
        flexBasis: '100%',
        flexDirection: 'column',
        width: '50%',
        height: '100%',
    },
    [`& .${classes.inner}`]: {
        border: '1px solid rgb(221, 221, 221)',
        borderRadius: '12px',
        padding: theme.spacing(3, 2, 2),
    },
    [`& .${classes.icon}`]: {
        margin: theme.spacing(0, 0, 1),
    },
    [`& .${classes.jetSkiIcon}`]: {
        marginRight: theme.spacing(2),
    },
}))

export interface ProductAssetsProps {
    product: ProductConfig
}

export const ProductAssets = (props: ProductAssetsProps) => {
    const { product } = props
    const { minPassengers, maxPassengers, withInstructor } = product

    const theme = useTheme()
    const translate = useTranslate()
    const isSmall = useMediaQuery(theme.breakpoints.down('md'))
    const isFixedNumPassengers = minPassengers === maxPassengers

    return (
        <>
            <Spacer />
            <div>
                <Typography component='h2' variant='h2'>
                    {translate(`product.assets.assets`)}
                </Typography>
                <StyledItem className={classes.root}>
                    <div className={classes.item}>
                        <div className={classes.inner}>
                            <SimpleSkiJetIcon className={clsx(classes.icon, classes.jetSkiIcon)} />
                            {
                                [...Array(maxPassengers)].map((_, i) => (
                                    <PassengerIcon key={`passenger-icon-${i}`} className={classes.icon} />
                                ))
                            }
                            <br/>
                            <Typography variant='highlight1'>
                                {
                                    maxPassengers > 1 ?
                                        translate('product.assets.customerJetSkiPlus') :
                                        translate('product.assets.customerJetSki')
                                }
                            </Typography>
                            <Typography variant='body2'>
                                {
                                    isFixedNumPassengers ?
                                        translate('product.assets.customer', { numPassengers: maxPassengers }) :
                                        translate('product.assets.customers', { minPassengers, maxPassengers })
                                }
                            </Typography>
                        </div>
                    </div>
                    {
                        withInstructor ? (
                            <div className={classes.item}>
                                <div className={classes.inner}>
                                    <SimpleSkiJetIcon className={clsx(classes.icon, classes.jetSkiIcon)} />
                                    <InstructorIcon className={classes.icon} />
                                    <br/>
                                    <Typography 
                                        component='p'
                                        variant='highlight1' 
                                        sx={{ marginBottom: isSmall ? '45px' : '25px' }}
                                    >
                                        {translate('product.assets.instructorJetSki')}
                                    </Typography>
                                </div>
                            </div>
                        ) : null
                    }
                </StyledItem>
            </div>
        </>
    )
}

ProductAssets.propTypes = {
    product: PropTypes.object,
}
