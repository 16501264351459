import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Container } from '@mui/material'
import { Helmet } from 'react-helmet-async'
import { NotFound } from '../main'
import { ProductAssets } from './ProductAssets'
import { ProductBooking } from './ProductBooking'
import { ProductCancellation } from './ProductCancellation'
import { ProductCarousel } from './ProductCarousel'
import { ProductConditions } from './ProductConditions'
import { ProductDescription } from './ProductDescription'
import { ProductDetails } from './ProductDetails'
import { ProductExtras } from './ProductExtras'
import { ProductMap } from './ProductMap'
import { ProductTicket } from './ProductTicket'
import { ProductTitle } from './ProductTitle'
import { getTicketUrlForChoiceAt, useProduct, useScrollToTop, useTranslate } from '../../service'


export const Product = () => {
    const product = useProduct()
    const translate = useTranslate()
    const [ticketUrl, setTicketUrl] = useState<string | undefined>(
        getTicketUrlForChoiceAt(product, 0)
    )

    const handleChoiceChange = (index: number) => {
        setTicketUrl(getTicketUrlForChoiceAt(product, index))
    }

    useScrollToTop()

    if (! product) {
        return (
            <NotFound />
        )
    }

    const { id } = product

    return (
        <>
            <Helmet prioritizeSeoTags>
                <title>Ocean Rider · {translate(`product.name.${id}`)}</title>
                <link rel='canonical' href={`https://oceanridertenerife.es/${id}`} />
                <link rel='alternate' href={`https://oceanridertenerife.es/${id}?hl=en`} hrefLang="en" />
            </Helmet>
            <div style={{ marginTop: '57px' }}>
                <ProductBooking 
                    displayPhoneOnDesktop
                    product={product} 
                    onChange={handleChoiceChange} 
                    selectOnClick
                />
                <ProductCarousel product={product} />
                <Container maxWidth='md' sx={{ pt: 2 }}>
                    <ProductTitle product={product} />
                    <ProductDetails product={product} />
                    <ProductDescription product={product} />
                    <ProductAssets product={product} />
                    <ProductExtras product={product} />
                    <ProductTicket 
                        product={product} 
                        ticketUrl={ticketUrl} 
                    />
                    <ProductConditions product={product} />
                    <ProductMap product={product} />
                    <ProductCancellation product={product} />
                </Container>
            </div>
        </>
    )
}

Product.propTypes = {
    product: PropTypes.object,
}
