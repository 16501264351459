import PropTypes from 'prop-types'
import React from 'react'
import { DurationConfig, ProductConfig, useTranslate } from '../../service'
import { Typography } from '@mui/material'

export interface ProductDetailsProps {
    product: ProductConfig
}

export const ProductDetails = (props: ProductDetailsProps) => {
    const { product } = props
    const {
        distance,
        durations,
        maxPassengers,
        minPassengers,
    } = product

    const translate = useTranslate()

    const isSingleDuration = durations.length === 1
    const isVariableDuration = isSingleDuration && (durations[0].minDuration !== durations[0].maxDuration)
    const isApproximateDuration = durations[0].approximate
    const isFixedNumPassengers = minPassengers === maxPassengers
    const isSinglePassenger = isFixedNumPassengers && maxPassengers === 1

    return (
        <Typography variant='body1'>
            {
                isSinglePassenger ?
                    translate('product.detalis.passenger') :
                isFixedNumPassengers ?
                    translate('product.details.passengers', { numPassengers: maxPassengers }) :
                    translate('product.details.passengerRange', { minPassengers, maxPassengers })
            }
            {' · '}
            {
                isVariableDuration ?
                    translate('product.details.durationRange', {
                        minDuration: durations[0].minDuration,
                        maxDuration: durations[0].maxDuration,
                    }) :
                    translate('product.details.durations', {
                        durations: durations.map((duration: DurationConfig) => duration.maxDuration ).join('/')
                    })
            }
            {
                isApproximateDuration ?
                    ' ' + translate('product.details.approx') : null
            }
            { distance > -1 ? ` · ${translate('product.details.distance', { distance })}` : null}
        </Typography>
    )
}

ProductDetails.propTypes = {
    product: PropTypes.object,
}
