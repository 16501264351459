export const LOCALE_PARAM = 'hl'

export type Translate = (key: string, options?: any) => string

export type TranslationDictionary = {
    [key: string]: TranslationDictionary | string | undefined
}

export type LocaleInfo = {
    locale: string
    name: string
}

export type Locale = {
    name: string
    messages: TranslationDictionary
}

export type Locales = {
    [locale: string]: Locale
}

export type I18nProvider = {
    locale: string
    localeInfo: LocaleInfo[]
    setLocale: (newLocale: string) => void
    translate: Translate
}

export type I18nProviderContext = {
    locale: string
    setLocale: (newLocale: string) => void
    i18nProvider: I18nProvider
}
