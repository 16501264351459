import React from 'react'
import { Container, Typography } from '@mui/material'
import { Link } from '../Link'
import { StyledSection, classes } from './Styled'
import { useTranslate } from '../../service'

export const NotFound = () => {
    const translate = useTranslate()

    return (
        <StyledSection className={classes.root}>
            <Container maxWidth='md'>
                <Typography
                    className={classes.h1}
                    component='h1'
                >
                    {translate('notFound.title')}
                </Typography>
                <Typography
                    className={classes.h2}
                    component='h2'
                >
                    {translate('notFound.description')}
                </Typography>
                <Link to='/'>
                    <Typography
                        component='p'
                        variant='body1'
                    >
                        {translate('notFound.back')}
                    </Typography>
                </Link>
                <Typography
                    component='p'
                    variant='body2'
                >
                    {translate('notFound.error', { code: 410 })}
                </Typography>
            </Container>
        </StyledSection>
    )
}
