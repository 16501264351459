import { useEffect } from 'react'
import { useConfig, useLocale, useSetLocale } from '../service'

const BANNER = [
    '                                       &#BBB#&                                                      ',
    '                                     &BBBBBBBBB#                                                    ',
    '                                    &BBBBBBBBBBBB#                                                  ',
    '                                  &&BBBBBBBBBBBBBBB#&                                               ',
    '                               #BBBBBBBBBBBBBBBBBBBBB                                               ',
    '                             #BBBBBBBBBBBBBBBBBB&                                                   ',
    '                           &BBBBBBBBBBBBBBBBBBB&                                                    ',
    '                          #BBBBBBBBBBBBBBBBBBBB                                                     ',
    '                        &BBBBBBBBBBBBBBBBBBBBB#                                                     ',
    '                       &BBBBBBBBBBBBBBBBBB&##&                                                      ',
    '                      &BBBBBBBBBBBBBBBBBB#                                                          ',
    '                     &BBBBBBBBBBBBBBBBBBBBB#&                                                       ',
    '                     &BBBBBBBBBBBBBBBBBBBBBBBB#&&                                                   ',
    '                      BBBBBBBBBBBBBBBBBBBBBBBBBBBB###&   &BBBBBBBBBB##&&                            ',
    '                     &BBBBBBBBBBBBB##BBBBBBBBBBBBBBBBBB&&#BBBBBBBBBBBBBBB#&                         ',
    '                     #BBBBBBBBBBBB&     &&&&##BBBBBBBBBBBBBBBBBBBBBBBBBBBBBB#&                      ',
    '                     #BBBBBBBBBBBB&            &#BBBBBBBBBBBBBBBBBBBBBBBBBBBBB&                     ',
    '                      BBBBBBBBBBBBBB&            &BBBBBBBBBBBBBBBBBBBBBBBBBBBB&                     ',
    '                      &BBBBBBBBBBBBBB#&         &BBBBBBBBBBBBBBBBBBBBBBBBBBBBB&&&&&&&&&&            ',
    '                        &BBBBBBBBBBBBBB#      &BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB#&',
    '                          &#BBBBBBBBBBBBB#   &BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB#',
    '                             #BBBBBBBBBBBBB&&BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB# ',
    '                               &BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB# ',
    '                                 #BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB& ',
    '                               &##BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB&  ',
    '               &&&##BBBBBB###BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB&   ',
    '           ##BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB#     ',
    '          #BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB&      ',
    '          #BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB&        ',
    '           #BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB#          ',
    '            &&#&&&&&&###BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB#            ',
    '                        &BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB#              ',
    '                      &&#BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB#&                ',
    '                 &&#BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB#&                   ',
    '             &&#BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB#&                      ',
    '          &#BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB##&                          ',
    '         BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB#&&                              ',
    '         BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB#&&                                   ',
    '        #BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB#&&                                        ',
    '     &#BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB##&&                                             ',
    '  &#BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB#&&                                                   ',
    '&BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB##&&                                                        ',
    'BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB##&&                                                              ',
    '&BBBBBBBBBBBBBBBBBBBBBBBBBBB##&&                                                                    ',
    ' BBBBBBBBBBBBBBBBBBBBB##&&                                                                          ',
    ' &BBBBBBBBBBBBBB##&&                                                                                ',
    '  &######BB#&&                                                                                      ',
]

export const ConsoleBanner = () => {
    const { contact } = useConfig()
    const { emailAddress } = contact

    // Fix: in mobile, during the first render, some components
    // are lacking translation
    const locale = useLocale()
    const setLocale = useSetLocale()

    useEffect(() => {
        console.info(BANNER.join('\n'))
        console.info(`(c) 2019-${new Date().getFullYear()} Ocean Rider, SLU`)
        console.info(`Contact us at ${emailAddress}`)

        setLocale(locale)
        // eslint-disable-next-line
    }, [])

    return null
}
