import { Locales } from '../types'
//import de from './de'
import en from './en'
import es from './es'
//import it from './it'
//import pt from './pt'

const locales: Locales = {
    es,
    en,
//    de,
//    it,
//    pt,
}

export default locales
