import BackIcon from '@mui/icons-material/ArrowBackIosNew'
import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { AppBar, Divider, IconButton, Toolbar, Typography, styled } from '@mui/material'
import { LocaleSwitcher } from './LocaleSwitcher'
import { Link } from './Link'
import { OceanRiderIcon } from './icon'
import { useProduct, useTranslate } from '../service'

const PREFIX = 'OrHeader'

const classes = {
    root: `${PREFIX}-root`,
    icon: `${PREFIX}-icon`,
    link: `${PREFIX}-link`,
    logo: `${PREFIX}-logo`,
    appbar: `${PREFIX}-appbar`,
    back: `${PREFIX}-back`,
    divider: `${PREFIX}-divider`,
    title: `${PREFIX}-title`,
}

const StyledAppBar = styled(AppBar)(({ theme }) => ({
    [`&.${classes.root}`]: {
        backgroundColor: 'white',
        borderBottom: '1px solid #ebebeb',
        overflow: 'hidden',
        zIndex: 9999,
    },
    [`& .${classes.link}`]: {
        marginLeft: '-16px',
        marginBottom: 0,
        transition: 'all .3s ease-in',
        transform: 'translateY(0)',
        [`&.scrolledUp`]: {
            transform: 'translateY(-128px)',
        },
    },
    [`& .${classes.logo}`]: {
        width: '140px',
    },
    [`& .${classes.appbar}`]: {
        alignItems: 'center',
        display: 'flex',
        position: 'absolute',
        transition: 'all .3s ease-in',
        transform: 'translateY(128px)',
        [`&.scrolledUp`]: {
            transform: 'translateY(0)',
        },
    },
    [`& .${classes.back}`]: {
        margin: 0,
    },
    [`& .${classes.title}`]: {
        margin: 0,
        width: 'calc(100vw - 160px)',
    },
    [`& .${classes.divider}`]: {
        height: theme.spacing(4),
        margin: theme.spacing(0.5, 2),
    },
}))

export const Header = () => {
    const product = useProduct()
    const translate = useTranslate()
    const [scrollY, setScrollY] = useState<number>(window.scrollY)
    const isScrolled = scrollY > 64 && !! product

    const handleScroll = () => {
        setScrollY(window.scrollY)
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    })

    return (
        <StyledAppBar
            className={classes.root}
            elevation={0}
        >
            <Toolbar>
                <Link 
                    aria-label={translate('app.links.home')}
                    className={clsx(classes.link, {
                        scrolledUp: isScrolled,
                    })} 
                    to='/' 
                    sx={{ flexGrow: 1 }}
                >
                    <OceanRiderIcon className={classes.logo} />
                </Link>
                {
                    product ? (
                        <div className={clsx(classes.appbar, {
                            scrolledUp: isScrolled,
                        })}>
                            <Link
                                className={classes.back}
                                sx={{ flexGrow: 1 }}
                                to='/'
                            >
                                <IconButton>
                                    <BackIcon />
                                </IconButton>
                            </Link>
                            <Divider
                                className={classes.divider}
                                orientation='vertical'
                                flexItem
                            />
                            <Typography
                                className={classes.title}
                                noWrap
                            >
                                {translate(`product.name.${product.id}`)}
                            </Typography>
                        </div>
                    ) : null
                }
                <LocaleSwitcher 
                    sx={{ ml: 'auto' }}
                    selectOnClick    
                />
            </Toolbar>
        </StyledAppBar>
    )
}
