import { styled } from '@mui/material'

const PREFIX = 'OrSection'

export const classes = {
    root: `${PREFIX}-root`,
    h1: `${PREFIX}-h1`,
    h2: `${PREFIX}-h2`,
    h3: `${PREFIX}-h3`,
    text: `${PREFIX}-text`,
    bold: `${PREFIX}-bold`,
    link: `${PREFIX}-link`,
    noGutter: `${PREFIX}-no-gutter`,
}
export const StyledSection = styled('section')(({ theme }) => ({
    [`&.${classes.root}`]: {
        margin: theme.spacing(12, 0, 8),
        minHeight: `calc(100vh - 684px)`,
    },
    [`& .${classes.h1}`]: {
        color: '#222',
        fontSize: '32px',
        fontWeight: 400,
        marginBottom: theme.spacing(3),
    },
    [`& .${classes.h2}`]: {
        color: '#222',
        fontSize: '24px',
        fontWeight: 600,
        margin: theme.spacing(3, 0, 2),
    },
    [`& .${classes.h3}`]: {
        color: '#222',
        fontSize: '18px',
        fontWeight: 600,
        margin: theme.spacing(3, 0, 2),
    },
    [`& .${classes.text}`]: {
        color: '#484848',
        fontSize: '16px',
        fontWeight: 400,
        marginBottom: theme.spacing(3),
    },
    [`& .${classes.bold}`]: {
        color: '#484848',
        fontSize: '16px',
        fontWeight: 600,
        marginBottom: theme.spacing(3),
    },
    [`& .${classes.link}`]: {
        color: '#484848',
        fontSize: '16px',
        fontWeight: 400,
        marginBottom: theme.spacing(3),
        textDecoration: 'underline',
        [`&:hover`]: {
            color: '#222',
        }
    },
    [`& .${classes.noGutter}`]: {
        marginBottom: 0,
    },
}))
