import Cookies from './Cookies'
import { ReactNode, useCallback, useMemo, useState } from 'react'
import { CONSENT_COOKIE, CookieProvider, CookieState } from './types'
import { CookieContext } from './CookieContext'

export interface CookieContextProviderProps {
    children: ReactNode
}

export const CookieContextProvider = (props: CookieContextProviderProps) => {
    const { children } = props

    const cookies: Cookies = useMemo(
        () => new Cookies(document.cookie),
        []
    )

    const [state, setState] = useState<CookieState>({
        cookies,
        consent: cookies.get(CONSENT_COOKIE) ? true : undefined,
    })

    const accept = useCallback((): void => {
        cookies.set(CONSENT_COOKIE, String(Date.now()), {
            maxAge: 90 * 84600000,
        })
        setState({ ...state, consent: true })
    }, [cookies, state, setState])

    const decline = useCallback((): void => {
        cookies.removeAll()
        setState({ ...state, consent: false })
    }, [cookies, state, setState])

    const value: CookieProvider = useMemo(
        () => ({ ...state, accept, decline }),
        [state, accept, decline]
    )

    return (
        <CookieContext.Provider value={value}>
            {children}
        </CookieContext.Provider>
    )
}
