import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useConsent, useLogEvent } from '../service'

export const PageView = () => {
    const consent = useConsent()
    const logEvent = useLogEvent()
    const location = useLocation()

    // By passing `location.pathname` to the second argument of `useEffect`,
    // we only log on first render and when the `pathname` changes
    useEffect(() => {
        if (consent) {
            logEvent('page_view', { page_location: location.pathname })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [consent, location.pathname])

    return null
}
