import PropTypes from 'prop-types'
import React from 'react'
import { ProductConfig, useTranslate } from '../../service'
import { Spacer } from '../Spacer'
import { Typography } from '@mui/material'

export interface ProductDescriptionProps {
    product: ProductConfig
}

export const ProductDescription = (props: ProductDescriptionProps) => {
    const { product } = props
    const { id } = product

    const translate = useTranslate()

    return (
        <Typography variant='body1' sx={{ mt: 4 }}>
            {
                translate(`product.description.${id}`)
            }
        </Typography>
    )
}

ProductDescription.propTypes = {
    product: PropTypes.object,
}
