import Cookies from './Cookies'

export const CONSENT_COOKIE = 'consent'
export const LOCALE_COOKIE = 'locale'

export type Cookie = any

export interface CookieGetOptions {
    doNotParse?: boolean
}

export interface CookieSetOptions {
    path?: string
    expires?: Date
    maxAge?: number
    domain?: string
    secure?: boolean
    httpOnly?: boolean
    sameSite?: boolean | 'none' | 'lax' | 'strict'
    encode?: (value: string) => string
}

export interface CookieChanged {
    name: string
    value?: any
    options?: CookieSetOptions
}

export type CookieChangeListener = (options: CookieChanged) => void

export interface CookieParseOptions {
    decode: (value: string) => string
}

export interface CookieState {
    cookies: Cookies
    consent?: boolean
}

export type CookieProvider = {
    cookies: Cookies
    consent?: boolean
    accept: () => void
    decline: () => void
}
