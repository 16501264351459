import React from 'react'
import { Link } from '../Link'
import {
    BOOK,
    getAvailableDurations,
    getPrice,
    useConfig,
    useCurrency,
    useLogEvent,
    useTranslate
} from '../../service'
import {
    Button,
    Container,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    styled,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material'

const PREFIX = 'OrProducts'

const classes = {
    list: `${PREFIX}-root`,
    item: `${PREFIX}-item`,
    link: `${PREFIX}-link`,
    bar: `${PREFIX}-bar`,
    text: `${PREFIX}-text`,
    picture: `${PREFIX}-picture`,
    badge: `${PREFIX}-badge`,
    button: `${PREFIX}-button`,
    price: `${PREFIX}-price`,
    regular: `${PREFIX}-regular`,
}

const StyledList = styled(ImageList)(({ theme }) => ({
    [`&.${classes.list}`]: {
        marginTop: theme.spacing(4),
    },
    [`& .${classes.item}`]: {
        marginBottom: theme.spacing(4),
        [theme.breakpoints.down('md')]: {
            width: 'calc(100vw - 32px)',
        },
    },
    [`& .${classes.link}`]: {
        display: 'block',
    },
    [`& .${classes.picture}`]: {
        borderRadius: '8px',
        display: 'flex',
        overflow: 'hidden',
        position: 'relative',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            height: 'calc(100vw - 32px)',
            maxHeight: 'calc(100vw - 32px)',
        },
    },
    [`& .${classes.picture} img`]: {
        objectFit: 'cover',
        height: '418px',
        minHeight: '418px',
        width: '100%',
    },
    [`& .${classes.badge}`]: {
        backgroundColor: '#ffb700',
        borderRadius: '4px',
        color: '#222',
        fontSize: '0.75rem',
        fontWeight: '600',
        left: theme.spacing(2),
        padding: theme.spacing(0.25, 0.75),
        position: 'absolute',
        top: theme.spacing(2),
        textTransform: 'uppercase',
    },
    [`& .${classes.button}`]: {
        bottom: '94px',
        height: theme.spacing(6),
        position: 'absolute',
        right: '16px',
        textTransform: 'none',
        [theme.breakpoints.down('md')]: {
            bottom: 'auto',
            top: 'calc(100vw - 22px)',
        },
    },
    [`& .${classes.bar}`]: {
        maxWidth: '100%',
        width: '100%',
    },
    [`& .${classes.text}`]: {
        maxWidth: '100%',
        width: '100%',
        paddingRight: '96px',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('md')]: {
            whiteSpace: 'normal',
        },
    },
    [`& .${classes.price}`]: {
        marginTop: theme.spacing(2),
    },
    [`& .${classes.regular}`]: {
        fontWeight: 400,
    },
}))

export const Products = () => {
    const currency = useCurrency()
    const logEvent = useLogEvent()
    const translate = useTranslate()
    const theme = useTheme()
    const isSmall = useMediaQuery(theme.breakpoints.down('md'))
    const isLarge = useMediaQuery(theme.breakpoints.up('lg'))
    const { contact, layout, products } = useConfig()
    const { phone } = contact
    const { lg, md, sm } = layout
    const tel = `tel:${phone.replace(/\D/g, '')}`

    const handleBook = (id: string, channel: string) =>
        () => {
            logEvent(BOOK, {
                channel,
                product: id,
                where: 'products',
            })
        }

    return (
        <Container maxWidth='md'>
            <StyledList
                className={classes.list}
                cols={isSmall ? sm : isLarge ? lg : md}
                gap={16}
                variant='standard'
            >
                {
                    products.map((product, i) => {
                        const { 
                            durations,
                            id, 
                            maxPassengers, 
                            minPassengers, 
                            route, 
                            square,
                        } = product

                        const isFixedNumPassengers = minPassengers === maxPassengers
                        const isSinglePassenger = isFixedNumPassengers && maxPassengers === 1
                        const isApproximateDuration = durations[0].approximate
                        const effectiveDurations = getAvailableDurations(product)
                        const price = getPrice(product)

                        return (
                            <ImageListItem
                                className={classes.item}
                                key={i}
                                cols={1}
                                rows={1}
                            >
                                <Link className={classes.link} to={`${id}`}>
                                    <picture className={classes.picture}>
                                        <img
                                            alt={translate(`product.name.${id}`)}
                                            loading='lazy'
                                            src={square}
                                            style={{ width: '100%' }}
                                        />
                                        <span className={classes.badge}>
                                            {translate(route ? 'product.details.route' : 'product.details.freeRide')}
                                        </span>
                                    </picture>
                                    <ImageListItemBar
                                        className={classes.bar}
                                        title={
                                            <Typography
                                                className={classes.text}
                                                component='h2'
                                                variant='highlight1'
                                            >
                                                {translate(`product.name.${id}`)}
                                            </Typography>
                                        }
                                        subtitle={
                                            <>
                                                <Typography
                                                    className={classes.text}
                                                    component='p'
                                                    gutterBottom
                                                    variant='body2'
                                                >
                                                    {
                                                        isSinglePassenger ?
                                                            translate('product.detalis.passenger') :
                                                            isFixedNumPassengers ?
                                                                translate('product.details.passengers', { numPassengers: maxPassengers }) :
                                                                translate('product.details.passengerRange', { minPassengers, maxPassengers })
                                                    }
                                                    {' · '}
                                                    {
                                                        translate('product.details.duration', { duration: effectiveDurations.join('/') })
                                                    }
                                                    {
                                                        isApproximateDuration ?
                                                            ' ' + translate('product.details.approx') : null
                                                    }
                                                </Typography>
                                                
                                                {/*
                                                <Typography
                                                    className={classes.price}
                                                    component='p'
                                                    variant='highlight2'
                                                >
                                                    <span className={classes.regular}>
                                                        {
                                                            translate('product.details.since')
                                                        }
                                                    </span>
                                                    <span>
                                                        {' '}
                                                    </span>
                                                    <span>
                                                        {
                                                            currency(price)
                                                        }
                                                    </span>
                                                </Typography>
                                                */}
                                            </>
                                        }
                                        position='below'
                                    />
                                </Link>
                                <Button
                                    className={classes.button}
                                    disableElevation
                                    href={tel}
                                    onClick={handleBook(id, 'phone')}
                                    variant='contained'
                                >
                                    {translate('product.booking.book')}
                                </Button>
                            </ImageListItem>
                        )
                    })
                }
            </StyledList>
        </Container>
    )
}
