import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useScrollToTop } from '../../service'
import { Banner } from '../Banner'
import { Hero } from '../Hero'
import { Products } from '../product'

export const Home = () => {
    useScrollToTop()

    return (
        <div className="home">
            <Helmet prioritizeSeoTags>
                <title>Ocean Rider</title>
                <link rel='canonical' href='https://oceanridertenerife.es/' />
                <link rel='alternate' href='https://oceanridertenerife.es/?hl=en' hrefLang="en" />
            </Helmet>
            <Hero />
            <Banner />
            <Products />
        </div>
    )
}
