import BackIcon from '@mui/icons-material/ArrowBackIosNew'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { ProductConfig, useTranslate } from '../../service'
import { Drawer, IconButton, ImageList, ImageListItem, Toolbar, Typography, styled, Divider } from '@mui/material'

const PREFIX = 'OrHero'

const classes = {
    main: `${PREFIX}-main`,
    image: `${PREFIX}-image`,
    container: `${PREFIX}-container`,
    counter: `${PREFIX}-counter`,
    paper: `${PREFIX}-paper`,
    appbar: `${PREFIX}-appbar`,
    title: `${PREFIX}-title`,
    divider: `${PREFIX}-divider`,
    images: `${PREFIX}-images`,
}

const StyledImages = styled('div')(({ theme }) => ({
    [`& .${classes.container}`]: {
        display: 'flex',
    },
    [`& .${classes.main}`]: {
        margin: '0 auto',
        position: 'relative',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            height: '560px',
            overflow: 'hidden',
            width: '900px',
        },
    },
    [`& .${classes.image}`]: {
        cursor: 'pointer',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            marginTop: '-50px',
        },
    },
    [`& .${classes.counter}`]: {
        color: 'white',
        backgroundColor: 'rgba(34, 34, 34, 0.66)',
        borderRadius: '4px',
        bottom: '16px',
        fontSize: '12px',
        fontWeight: 500,
        padding: '3px 10px',
        position: 'absolute',
        right: '16px',
    },
}))

const StyledDrawer = styled(Drawer)(({ theme }) => ({
    [`& .${classes.paper}`]: {
        height: '100vh',
        width: '100vw',
        zIndex: 2,
    },
    [`& .${classes.appbar}`]: {
        backgroundColor: 'white',
        borderBottom: '1px solid #ebebeb',
        zIndex: 3,
    },
    [`& .${classes.title}`]: {
        margin: 0,
        width: 'calc(100vw - 160px)',
    },
    [`& .${classes.divider}`]: {
        margin: theme.spacing(2),
    },
    [`& .${classes.images}`]: {
        marginBlockStart: 0,
    },
}))

const srcset = (image: string, size: number, rows = 1, cols = 1) => {
    return {
        src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
        srcSet: `${image}?w=${size * cols}&h=${
            size * rows
        }&fit=crop&auto=format&dpr=2 2x`,
    };
}

export interface ProductImagesProps {
    product: ProductConfig
}

export const ProductImages = (props: ProductImagesProps) => {
    const { product } = props
    const { id, images, poster } = product

    const [open, setOpen] = useState<boolean>(false)
    const translate = useTranslate()
    const alt = translate(`product.name.${id}`)

    const toggleDrawer = () => {
        setOpen(open => ! open)
    }

    return (
        <StyledImages>
            <div className={classes.container}>
                <picture className={classes.main}>
                    <img
                        alt={alt}
                        className={classes.image}
                        src={poster}
                        onClick={toggleDrawer}
                    />
                    <span className={classes.counter}>
                        {`1/${images.length}`}
                    </span>
                </picture>
            </div>
            <StyledDrawer
                anchor='right'
                classes={{
                    paper: classes.paper,
                }}
                open={open}
                onClose={toggleDrawer}
            >
                <Toolbar className={classes.appbar}>
                    <IconButton onClick={toggleDrawer}>
                        <BackIcon />
                    </IconButton>
                    <Divider 
                        className={classes.divider}
                        orientation='vertical' 
                        flexItem
                    />
                    <Typography
                        className={classes.title}
                        noWrap
                    >
                        {translate(`product.name.${id}`)}
                    </Typography>
                </Toolbar>
                <ImageList
                    className={classes.images}
                    variant='quilted'
                >
                    {
                        images.map((item, i) => {
                            const { src, cols = 1, rows = 1 } = item

                            return (
                                <ImageListItem key={i} cols={cols} rows={rows}>
                                    <img
                                        alt={alt}
                                        loading='lazy'
                                        {...srcset(src, window.innerWidth / 2, rows, cols)}
                                    />
                                </ImageListItem>
                            )
                        })
                    }
                </ImageList>
            </StyledDrawer>
        </StyledImages>
    )
}

ProductImages.propTypes = {
    product: PropTypes.object,
}
