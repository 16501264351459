import React, { useState } from 'react'
import { Alert, Box, Button, Collapse, useMediaQuery, useTheme } from '@mui/material'
import { useCookieProvider, useTranslate } from '../service'

export const CookieConsent = () => {
    const theme = useTheme()
    const isSmall = useMediaQuery(theme.breakpoints.down('md'))
    const translate = useTranslate()
    const { consent, cookies, accept, decline } = useCookieProvider()

    const [open, setOpen] = useState(typeof consent === 'undefined' && cookies.enabled)

    const handleAccept = () => {
        accept()
        setOpen(false)
    }

    const handleDecline = () => {
        decline()
        setOpen(false)
    }

    const isOnCookiesScreen = (/\/cookie-policy/).test(window.location.pathname)

    return (
        <Box sx={{
            bottom: 0,
            position: 'fixed',
            width: '100%',
            /* Place the box over the map attribution */
            zIndex: 9999,
        }}>
            <Collapse in={open}>
                <Alert
                    severity='info'
                    sx={{ margin: isSmall ? 0 : '8px' }}
                    action={
                        <>
                            <Button
                                color='inherit'
                                onClick={handleDecline}
                                size='small'
                            >
                                {translate('cookies.consent.decline')}
                            </Button>
                            <Button
                                color='inherit'
                                onClick={handleAccept}
                                size='small'
                                sx={{ marginLeft: theme.spacing(1) }}
                            >
                                {translate('cookies.consent.accept')}
                            </Button>
                        </>
                    }
                >
                    {translate('cookies.consent.message')}
                    {
                        isOnCookiesScreen ? null : ' '
                    }
                    {
                        isOnCookiesScreen ? null : (
                            <a href='/cookie-policy'>
                                {translate('app.links.cookies')}
                            </a>
                        )
                    }
                </Alert>
            </Collapse>
        </Box>
    )
}
