import { Locale } from '../types'

const es: Locale = {
    name: 'Español',
    messages: {
        app: {
            title: {
                close: 'Cerrar',
                contactUs: 'Contacta con nosotros',
                data: 'Datos de la empresa',
                info: 'Ocean Rider',
                location: 'Dónde estamos',
                chooseLanguage: 'Escoge lenguaje',
                selectDuration: 'Escoge la duración',
                social: 'En las redes sociales',
            },
            links: {
                aboutUs: 'Sobre nosotros',
                contactUs: 'Contacta con nosotros',
                cookies: 'Política de cookies',
                email: 'Contacta con nosotros',
                faq: 'Preguntas Frecuentes',
                home: 'Inicio',
                terms: 'Términos y Condiciones',
                openInMaps: 'Abrir',
                phone: 'Contacta con nosotros',
                privacy: 'Política de privacidad',
                skipToContent: 'Ir al contenido',
                switchLocale: 'Cambiar lenguaje',
                whereWeAre: 'Dónde estamos',
            },
        },
        brand: {
            hash: '#weridetheocean',
            motto: 'Alquiler de motos de agua en Tenerife',
            name: 'Ocean Rider',
            schedule: 'De 10:00 a 20:00 h',
            workdays: 'Todos los días',
        },
        company: {
            label: {
                id: 'NIF',
                registry: 'Número de registro mercantil',
            },
        },
        cookies: {
            consent: {
                accept: 'Aceptar',
                accepted: 'Aceptaste el uso de cookies en este navegador. Si deseas optar por no utilizar cookies, puedes utilizar el botón a continuación:',
                decline: 'Rechazar',
                declined: 'Rechazaste el uso de cookies en este navegador.',
                message: 'Utilizamos cookies para mejorar tu experiencia de navegación.',
                optOut: 'Deseo rechazar el uso de cookies',
                undefined: 'Aún no has indicado tus preferencias sobre el uso de cookies en este navegador',
            },
            desc: {
                consent: 'Indica si el usuario ha dado su consentimiento para el uso de cookies',
                day: '1 día',
                days: '%{days} días',
                description: 'Descripción',
                expiry: 'Expiración',
                ga: 'Se utiliza para distinguir a los usuarios',
                gat: 'Se utiliza para distinguir a los usuarios',
                gid: 'Se usa para limitar el porcentaje de solicitudes. Si se ha implementado Google Analytics mediante Google Tag Manager, esta cookie se llamará "_dc_gtm_".',
                locale: 'Se utiliza para almacenar tus perferencias de lenguaje',
                minute: '1 minuto',
                provider: 'Proveedor',
                years: '%{years} años',
            },
            text: {
                aepd: 'disponible en este enlace',
                howto: 'Para administrar las cookies, consulta la documentación de tu navegador:',
                manage: 'El usuario tiene la opción de permitir, bloquear o eliminar las cookies instaladas en su equipo a través de la configuración de las opciones del navegador. Asimismo, puedes configurar el navegador para que acepte o rechace por defecto todas las cookies o para que aparezca un aviso en la pantalla cuando se reciba una cookie y decidir en cada momento si se almacena o no.',
                more: 'Tienes más información sobre cómo configurar tus opciones de privacidad en el sitio web de la Agencia Española de Protección de Datos, ',
                performance: 'Estas cookies se utilizan para obtener información estadística sobre el uso de nuestro sitio web, y también se denominan cookies analíticas. Utilizamos estos datos para mejorar el rendimiento y optimizar nuestro sitio web.',
                technical: 'Estas cookies se utilizan para manejar tu consentimiento y almacenar tus preferencias de lenguaje.',
                updated: 'Última actualización el 2 de julio de 2023',
                what: 'Las cookies son documentos de texto o piezas de código muy pequeños que a menudo contienen un código de identificación único. Cuando visitas un sitio web o usas una aplicación móvil, el navegador comprueba con tu ordenador o dispositivo móvil si tiene permiso para almacenar estos ficheros en tu dispositivo y obtener acceso a esta información. La información recolectada mediante cookies y tecnologías similares puede incluir la fecha y hora de la visita y cómo utilizas un sitio web o una aplicación móvil particular.',
                why: 'Usamos cookies para gestionar tu consentiiento y analizar cómo se usa nuestro sitio web y cómo podemos mejorarlo.',
            },
            title: {
                list: 'Lista de cookies que instalamos',
                manage: '¿Cómo administrar y eliminar las cookies en tu navegador?',
                performance: 'Cookies de rendimiento',
                technical: 'Cookies técnicas',
                types: '¿Qué tipos de cookies usamos?',
                what: '¿Qué son las cookies?',
                why: '¿Porqué usamos cookies?',
            },
        },
        locale: {
            en: 'English',
            es: 'Español',
            it: 'Italiano',
        },
        notFound: {
            back: 'Volver a la página de inicio',
            description: 'No podemos encontrar la página solicitada',
            error: 'Código de error: %{code}',
            title: 'Oops!',
        },
        privacy: {
            text: {
                updated: 'Última actualización el 2 de julio de 2023',
                bold: {
                    concerns: '¿Dudas o preguntas?',
                    personalInfo: '¿Qué información personal procesamos?',
                    sensitiveInfo: '¿Qué tipo de información personal sensible procesamos?',
                    thirdPartyInfo: '¿Recibimos información de terceras partes?',
                    processInfo: '¿Cómo procesamos la información que recogemos?',
                    shareInfo: '¿En qué situaciones y con quién compartimos la información que recogemos?',
                    keepInfo: '¿Cómo mantenemos la información que recogemos?',
                    rightsInfo: '¿Cuáles son tus derechos?',
                    exerciseRightsInfo: '¿Cómo puedes ejercer tus derechos de privacidad?',
                    moreInfo: '¿Quieres saber más acerca de cómo procesamos la información que recogemos?',
                    short: 'En resumen',
                    consent: 'Consentimiento',
                    legal: 'Obligaciones legales',
                    analytics: 'Analítica web y móvil',
                    vendors: 'Proveedores, consultores y otros proveedores de servicios',
                    access: 'Acceder a tus datos personales',
                    modification: 'Rectificarlos cuando son inextactos o incompletos',
                    removal: 'Solicitar la supresión o cancelación de los datos',
                    oppose: 'Oponerse al tratamiento de los datos que recogemos',
                    limit: 'Limitar el tratamiento, cuando se dé alguna de las condiciones previstas en la normativa',
                    portability: 'Solicitar la portabilidad de sus datos en los casos previstos en la normativa',
                    automation: 'Impedir ser objeto de decisiones automatizadas',
                    revoke: 'Revocar los consentimientos otogados',
                },
                data: {
                    usage: 'Datos de uso',
                    device: 'Datos del dispositivo',
                    location: 'Datos de localización',
                },
                links: {
                    processInfo: 'Saber más sobre cómo procesamos la información que recogemos',
                    keepInfo: 'Saber más sobre cómo mantenemos la información que recogemos',
                    rightsInfo: 'Saber más sobre tus derechos de privacidad',
                },
                intro: {
                    1: 'Esta Política de Privacidad de %{company} (en adelante "nosotros", "nuestro/a"), describe cómo y porqué recogemos, almacenamos, usamos y/o gestionamos ("procesamos") tu información cuando utilizas nuestros servicios, como cuando:',
                    2: 'Visitas nuestro sitio web %{url}',
                    3: 'Te relacionas con nosotros de otras formas',
                    4: 'Este documento te ayudará a entender tus derechos y opciones de privacidad. Si no estás de acuerdo con nuestras políticas y prácticas, por favor, no utilices nuestros servicios. Si tienes cualquier duda o pregunta, por favor, contacta con nosotros utilizando el correo electrónico %{emailAddress}.',
                },
                summary: {
                    1: 'Esta sección resume los puntos clave de nuestra Política de Privacidad, pero puedes encontrar más detalles acerca de cualquiera de estos puntos utilizando los enlaces que proporcionamos o la tabla de contenidos a continuación para encontrar la sección que buscas.',
                    2: 'No recogemos información personal sobre tí en nuestro sitio web.',
                    3: 'No procesamos información personal sensible o crítica.',
                    4: 'No recibimos información de terceras partes.',
                    5: 'Procesamos la información que recogemos para proporcionar, mejorar y gestionar nuestros servicios y para cumplir con la normativa legal vigente. Sólo procesamos información cuando tenemos una razón legal para hacerlo, y sólo con tu consentimiento.',
                    6: 'Podemos compartir información que recogemos con terceras partes, sólo en casos específicos y con terceras partes específicas.',
                    7: 'Protegemos la información personal que recogemos.',
                    8: 'Tienes derecho a acceder, rectificar y cancelar los datos que recogemos sobre tí, oponerte a su tratamiento, limitar el tratamiento, solicitar la portabilidad, impedir ser objeto de decisiones automatizadas y revocar los consentimientos otorgados.',
                    9: 'La forma más sencilla de ejercitar tus derechos es enviar una solicitud de acceso, o contactando con nosotros. Consideraremos y actuaremos ante cualquier solicitud, de acuerdo con las leyes aplicables de protección de datos.',
                    10: 'Revisa el texto completo de esta Política de Privacidad.',
                },
                collection: {
                    1: 'No recogemos información personal sobre tí en nuestro sitio web.',
                    2: 'Sólo recogeremos la información personal necesaria para la prestación de los servicios si contactas con nosotros a través de cualquiera de los medios establecidos en este sitio web.',
                    3: 'No procesmos información personal sensible o crítica.',
                    4: 'Recogemos algunas piezas de información automáticamente cuando visitas nuestro sitio web, como tu dirección de Internet (IP) y/o datos y características de tu navegador y de tu dispositivo.',
                    5: 'Cuando visitas y navegas por nuestro sitio web, utilizamos herramientas para recoger información de forma automatizada. Esta información no revela tu identidad (como tu nombre o tu información de contacto), pero puede incluir información acerca de tu dispositivo y la forma en que usas nuestros servicios, como tu dirección IP, tu navegador, las características de tu dispositivo, tu sistema operativo, tus preferencias de lenguaje, las URLs de las que provienes, el nombre del dispositivo, el país y la localización, información acerca de cómo y cuándo utilizas nuestros servicios, y otra información técnica. Esta información se utiliza principalmente para mantener y mejorar nuestros servicios, y para propósitos de analítica.',
                    6: 'Como la mayoría de sitios, recogemos esta información a través de cookies y tecnologías similares. Puedes encontrar más información en nuestra ',
                    7: 'La información automática que recogemos incluye:',
                    8: 'Recogemos datos relacionados con el uso de nuestros servicios, datos de diagnóstico e información de rendimiento en nuestros servidores cuando accedes a nuestros servicios, información que recogemos en ficheros de registro. Dpendiendo de la forma en que interactúas con nuestros servicios, estos datos pueden incluir tu dirección IP, información del dispositivo, tipo y configuraciones de tu navegador, e información acerca de tu actividad en nuestros servicios (tales como la fecha y hora asociada con el uso, las páginas que visitas, y otras acciones que llevas a cabo), información del dispositivo y del hardware que usas.',
                    9: 'Recogemos información de los dispositivos que acceden a nuestros servicios. Dependiendo del dispositivo empleado, estos datos pueden incluir información como tu dirección IP (o del servidor proxy, en su caso), números de identificación del dispositivo o aplicación que utilizas, localización, tipo de navegador, modelo hardware, proveedor de servicios de Intenet y/o operador, sistema operativo e información de configuración del sistema.',
                    10: 'Recogemos información de la localización de tu dispositivo cuando accedes a nuestros servicios, cuyo nivel de precisión puede variar. La cantidad y el tipo de información que recogemos depende del tipo y la configuración del dispositivo que uses para acceder a nuestros servicios. Por ejemplo, podemos utilizar tecnologías para recoger datos de geolocalización que indican la localización de tu dispositivo (basada en tu dirección IP). Puedes rechazar la recogida de esta información deshabilitando la configuración de localización de tu dispositivo.',
                },
                processing: {
                    1: 'Procesamos la información que recogemos para proporcionar, mejorar y gestionar nuestros servicios, y para cumplir con la normativa legal vigente.',
                },
                legalBasis: {
                    1: 'Sólo procesamos información con tu consentimiento y para cumplir con nuestras obligaciones contractuales bajo las leyes aplicables.',
                    2: 'El Reglamento General de Protección de Datos (RGPD) requiere que expliquemos las bases legales sobre las que se sustenta el procesado de la información que recogemos. De acuerdo con esto, nos basamos en las siguientes bases legales para procesar esta información:',
                    3: 'Sólo procesamos información con tu consentimiento. Puedes rechazar dicho consentimiento en cualquier momento.',
                    4: 'Procesamos la información para cumplir con las obligaciones contractuales.',
                },
                sharing: {
                    1: 'Podemos compartir la información que recogemos en las situaciones específicas y/o con las terceras partes descritas en esta sección.',
                    2: 'Podemos compartir la información que recogemos con proveedores, consultores y otros proveedores de servicios ("terceros") que nos ayudan a prestar nuestros servicios o necesitan acceso a dicha información. Estos terceros son los siguientes:',
                },
                tracking: {
                    1: 'Podemos utilizar cookies y otras tecnologías para recoger y almacenar la información que procesamos.',
                    2: 'Podemos utilizar cookies y tecnologías similares (como web beacons y pixels de seguimiento) para acceder o almacenar información. Puedes obtener más información acerca de cómo utilizamos estas tecnologías y cómo puedes rechazar el uso de cookies consultando nuestra ',
                },
                keeping: {
                    1: 'Mantenemos la información que recogemos el tiempo requerido por los propósitos descritos en esta Política de Privacidad, de acuerdo con los requisitos legales.',
                    2: 'Sólo mantenemos la información que recogemos el tiempo requerido por los propósitos descritos en esta Política de Privacidad, a menos que la normativa requiera un período de retención mayor.',
                    3: 'Cuando no tenemos necesidades legítimas para seguir procesando tu información, borramos o anonimizamos tal información, o, si esto no es posible (por ejemplo, porque la información personal que recogemos se almacena en un archivo), almacenamos de forma segura y aislada dicha información, hasta que sea posible eliminarla.',
                },
                rights: {
                    1: 'Tienes derecho a acceder y controlar la información que recogemos acerca de ti. Puedes acceder, revisar, modificar o solicitar la eliminación la información en cualquier momento.',
                    2: 'Puedes ejercer los siguientes derechos ante %{company}:',
                    3: 'Por ejemplo, cuando hayan dejado de ser necesarios para los propósitos para los que fueron recogidos.',
                    4: 'Nos basamos en tu consentimiento para procesar los datos que recogemos sobre ti. Puedes rechazar este consentimiento en cualquier momento, como se describe en nuestra ',
                    5: 'Asimismo, tienes derecho a reclamar ante la Autoridad de Control competente (la Agencia Española de Protección de Datos) si consideras que hemos vulnerado los derechos que la normativa de protección de datos te reconoce, a través de su sitio web ',
                },
                access: {
                    1: 'Puedes ejercer tus derechos de privacidad utilizando los detalles proporcionados en la sección ',
                    2: 'Consideraremos y actuaremos ante cualquier solicitud, de acuerdo con la normativa vigente de protección de datos.',
                },
                contact: {
                    1: 'Si tienes cualquier comentario, duda o pregunta acerca nuestra Política de Privacidad, o si deseas ejercer tus derechos de privacidad, puedes contactar con nosotros a través de la dirección de correo electrónico %{emailAddress}, o mediante correo postal:',
                },
                updates: {
                    1: 'Actualizaremos esta Política de Privacidad para cumplir con la normativa vigente.',
                    2: 'Podemos actualizar esta Política de Privacidad cuando sea necesario. Cada versión publicada indicará la fecha de última actualización. Te animamos a revisar nuestra Política de Privacidad frecuentemente para estar informado.',
                },
            },
            title: {
                summary: 'Resumen',
                toc: 'Tabla de contenidos',
                collection: '¿Qué información recogemos?',
                processing: '¿Cómo procesamos la información que recogemos?',
                legalBasis: '¿Cuáles son las bases legales que sustentan el procesado de la información que recogemos?',
                sharing: '¿Cuándo y con quién compartimos la información que recogemos?',
                tracking: 'Uso de cookies y otras tecnologías de seguimiento',
                keeping: '¿Cuánto tiempo mantenemos la información que recogemos?',
                rights: '¿Cuáles son tus derechos de privacidad?',
                access: '¿Cómo puedes ejercer tus derechos?',
                contact: '¿Cómo puedes contactar con nosotros?',
                updates: '¿Cuándo actualizamos nuestra Política de Privacidad?',
                personal: 'Información personal que recogemos',
                sensitive: 'Información personal sensible que recogemos',
                automatic: 'Información automática que recogemos',
            },
        },
        product: {
            assets: {
                assets: 'Equipo',
                customer: '%{numPassengers} pasajeros',
                customers: '%{minPassengers}‒%{maxPassengers} pasajeros',
                customerJetSki: 'Tú',
                customerJetSkiPlus: 'Tú (y un acompañante)',
                instructor: '1 pasajero',
                instructorJetSki: 'Instructor',
            },
            booking: {
                action: 'Llamar al %{phone}',
                book: 'Reserva',
                buy: 'Compra Online',
                priceOnline: 'Compra online por %{ticketPrice} (%{ticketDiscount}% descuento)',
                ticket: 'Puedes hacer tu reserva online en la web de bonosvip, utilizando el siguiente enlace.',
                ticketUrl: 'Reservar en bonosvip',
                feeResident: '%{fee} residente',
                feeNonResident: '%{fee} no-residente',
                whatsapp: 'Chat en WhatsApp',
            },
            cancellation: {
                cancellableUntil: 'Es posible realizar cambios o cancelaciones a la reserva hasta %{cancellableUntil} horas antes del servicio. Si la cancelación se debe a las condiciones climáticas, es posible cambiar o cancelar sin cargos adicionales.',
                notAvailable: 'Este producto no acepta cancelación.',
                policy: 'Política de cancelación',
            },
            conditions: {
                age: 'Edad mínima',
                checkIn: 'Check-in',
                checkInTime: 'Check-in 30 minutos antes de la actividad.',
                fullText: 'Consulta las condiciones completas del servicio',
                harborFees: 'La autoridad del puerto carga %{price} por pasajero y viaje.',
                kids: 'Los niños mayores de 8 años pueden acompañar al conductor.',
                minAge: 'Todos los conductores deben ser mayores de 16 años.',
                onlyIfFavorableWeather: 'Las actividades se realizarán sólo si lo permiten las condiciones ambientales.',
                policy: 'Normas',
                weather: 'Estado del mar',
            },
            description: {
                'radazul-bay': '¿Primera vez en moto de agua? Nuestra tarifa de 20, 30 y 60 minutos es ideal tanto para principiantes como para pilotos experimentados. Sólo la moto, el océano y tú.',
                'candelaria': '¿Has oido hablar de Candelaria? De gran interés cultural arraigado en las costumbres de los tinerfeños, conforma uno de los lugares más icónicos de la isla. Atrévete a descubrirlo con nosotros desde el mar.',
                'malpais-guimar': '¡La mejor manera de disfrutar de paisajes asombrosos pilotando una moto de agua! Descubre el valle de Güímar con nuestra excursión guiada y déjate sorprender por las formaciones volcánicas que hacen del malpaís de Güímar una reserva natural única.',
                'punta-pachona': 'The activity begins at Radazul Marina riding along the coast heading east. Planned stops: Boca Cangrajo and Punta Pachona (Añaza). Will be possible to swim and take photos and videos.',
            },
            details: {
                approx: 'aprox.',
                distance: '%{distance} km aprox.',
                duration: '%{duration} minutos',
                durations: '%{durations} minutos',
                durationRange: '%{minDuration}‒%{maxDuration} minutos',
                freeRide: 'Free Ride',
                nonResident: '%{amount} No residentes',
                passenger: '1 pasajero',
                passengers: '%{numPassengers} pasajeros/moto',
                passengerRange: '%{minPassengers}‒%{maxPassengers} pasajeros',
                price: 'Desde %{price}',
                purchaseOnline: 'Compra online disponible',
                route: 'Ruta',
                seeOnMap: 'Abrir en mapas',
                since: 'Desde',
            },
            extra: {
                cancellableUntil: 'Cancelación gratuita hasta %{cancellableUntil} horas antes de la actividad',
                includesBriefing: 'Instrucción de navegación',
                includesInsurance: 'Incluye seguro',
                includesLifejacket: 'Chaleco salvavidas',
                includesLockerRoom: 'Baúl guardarropa y vestuario',
                includesPhotosAndVideos: 'Fotos',
                includesSwim: 'Baño',
                title: 'Qué incluye',
            },
            help: {
                distance: 'Distancia aproximada de la ruta',
                duration: 'Duración aproximada de la ruta',
                includesBriefing: 'Incluye cursillo sobre navegación, leyes aplicables e instrucción básica de la moto de agua',
                includesLifejacket: 'Incluye chaleco salvavidas para todos los pasajeros',
                includesLockerRoom: 'Incluye acceso a cambiador, baúl guardarropa y ducha caliente',
                includesPhotosAndVideos: 'Extra %{price} para todas las fotos de la actividad',
                includesSwim: 'Es posible nadar y sacar fotos',
            },
            name: {
                'radazul-bay': 'Bahía de Radazul',
                'candelaria': 'Candelaria',
                'malpais-guimar': 'Malpaís de Güímar',
                'punta-pachona': 'Punta Pachona (Añaza)',
            },
            stop: {
                'boca-cangrejo': 'Boca Cangrejo',
                'candelaria': 'Candelaria',
                'malpais-guimar': 'Malpaís de Güímar',
                'ocean-rider': 'OceanRider',
                'punta-pachona': 'Punta Pachona',
                label: 'Parada',
                origin: 'Salida/Llegada',
            },
        },
        terms: {
            text: {
                updated: 'Última actualización el 2 de julio de 2023',
                legal: {
                    1: 'Los datos identificativos del titular de la página web son: %{company}, con domicilio en %{address} y titular del CIF %{id}, e-mail: %{emailAddress}.',
                    2: 'El acceso a la página web es gratuito, con excepción de los servicios que se señalen expresamente a los que podrá acceder de acuerdo a las respectivas condiciones particulares según se expresan, en su caso, en las que se recogen los precios del producto o Servicio, indicando si se incluyen o no los impuestos aplicables y, en su caso, los gastos de envío.',
                    3: 'Esta información legal se complementa con la Política de Privacidad, con las Condiciones Generales de Uso de la Página Web y, en su caso, con las Condiciones Particulares de cada Producto o Servicio recogidas expresamente.',
                    4: '%{company}, no asume ninguna responsabilidad derivada del uso incorrecto o ilícito de la información facilitada a través de su/s página/s. Asimismo, y con los límites legales, no asume ninguna responsabilidad por la falta de exactitud, integridad o actualización de los datos o informaciones que se publiquen en la página web de la que es titular. %{company}, tampoco es responsable del contenido de otras páginas de Internet a las que puedan dirigirse los links o enlaces colocados en la página web de %{company}.',
                },
                conditions: {
                    1: 'El acceso al presente sitio web es responsabilidad de cada Usuario, quien se obliga a utilizar el sitio web de forma diligente, y comporta el conocimiento y aceptación sin reservas de ninguna clase, de las Condiciones Generales de Uso y de las Condiciones Generales de Contratación contenidas en el sitio web, así como de la Ley, las buenas costumbres y la buena fe, absteniéndose de utilizar el sitio web de forma que impida su normal funcionamiento, o que cause daños o perjuicios, directa o indirectamente, a los derechos legítimos de %{company} o a cualquier tercero. El usuario declara ser mayor de edad y disponer de la capacidad jurídica suficiente para vincularse en base a las presentes Condiciones Generales.',
                    2: 'Deberá abonar al empresario o a su empleado el precio fijado por los servicios contratados sin que el hecho de efectuar una reclamación exima del pago.',
                    3: 'El acceso y uso de ciertos contenidos y servicios puede encontrarse sometido a determinadas condiciones propias, avisos legales, directrices y reglamentos de uso que, previamente puestos en conocimiento del usuario, y según los casos, sustituirán, complementaran y/o modificaran las Condiciones Generales aquí recogidas. El acceso y uso de estos contenidos y servicios implicará la plena adhesión a las Condiciones Particulares que los regulen, quedando estas Condiciones Particulares automáticamente incorporadas a las presentes Condiciones Generales. En caso de contradicción entre Condiciones Generales y Particulares, prevalecerán las Condiciones Particulares respecto a los términos incompatibles y únicamente respecto a los contenidos y servicios sometidos a esta específica regulación.',
                },
                liability: {
                    1: 'A título meramente enunciativo, el Usuario se obliga a:',
                    2: 'Uso exclusivo del sitio web y de todos sus contenidos para finalidades lícitas y no prohibidas, que no infrinjan la legalidad vigente ni sean lesivas para %{company}, ni para cualquier tercero;',
                    3: 'No transmitir, difundir o poner a disposición de terceros informaciones, datos, contenidos, mensajes y en general cualquier material que pueda ser difamatorio, injurioso, obsceno, amenazador, xenófobo, incite a la violencia, a la discriminación o de cualquier forma atente contra la moral, el orden público, los derechos fundamentales, las libertades públicas, el  honor, la intimidad, la imagen de terceros y, en general, la normativa vigente;',
                    4: 'No difundir, almacenar o introducir mediante el sitio web ningún programa de ordenador, virus, datos, código, equipo de hardware o de telecomunicaciones o cualquier otro instrumento o dispositivo que sea susceptible de causar daños al sitio web o a cualquier sistema, equipo de %{company}, de cualquier proveedor de %{company} o de cualquier tercero;',
                    5: 'No realizar actividades publicitarias, promocionales o de explotación comercial a través del sitio web, no utilizando el contenido del sitio web para remitir publicidad, enviar mensajes con cualquier finalidad comercial, ni para almacenar datos personales de terceros;',
                    6: 'No utilizar identidades falsas, ni suplantar la identidad de otros en el uso del sitio web;',
                    7: 'No destruir, manipular inutilizar o dañar datos, informaciones, programas o documentos electrónicos de %{company}, de sus proveedores o de terceros;',
                    8: 'No se puede hipervincular el sitio web %{company} con cualquier otra página web sin previa autorización;',
                },
                rights: {
                    1: 'El Usuario se obliga a respetar los derechos de propiedad industrial e intelectual de %{company} y de cualquier otro tercero. Todos los contenidos que se muestran en el sitio web están sujetos a derechos de propiedad industrial y/o intelectual de %{company} o de terceros titulares de los mismos que han autorizado debidamente su inclusión en el sitio web, sin que se puedan entender cedidos al Usuario.',
                },
                disclaimer: {
                    1: '%{company}, no se responsabiliza de posibles errores en el contenido del sitio web. Los servicios ofrecidos y no sometidos a contratación previa, son ofrecidos al usuario sin ninguna contrapartida y únicamente comportan para el Usuario al obligación de cumplir las normas de uso establecidas, por tanto sobre estos servicios %{company} podrá suspender, retirar o cancelar parcial o totalmente los servicios no sometidos a contratación en cualquier momento sin necesidad de previo aviso. Asimismo %{company}, no garantiza la disponibilidad y continuidad del sitio web en cuanto a su funcionamiento, excluyendo de cualquier responsabilidad a %{company} en caso de daños y perjuicios sufridos por el usuario que se puedan derivar de la falta de disponibilidad y continuidad del funcionamiento del sitio web y de sus servicios.',
                    2: 'Estas Condiciones Generales se aplicaran tanto a la actividad promocional y de suministro de información que se efectúa a través del sitio web como a la prestación de los servicios ofrecidos al sitio web, por tanto regirán en todo momento, tanto para la simple navegación del sitio web como para la adquisición de los bienes y/o la prestación de servicios en el marco del sitio web, si bien estas últimas actividades se someterán adicionalmente, tanto a las Condiciones Generales de Uso, como a las Condiciones Generales de Contratación y las Condiciones Particulares que, en su caso, pudiesen existir.',
                    3: 'Es responsabilidad de cada Usuario adoptar todas las medidas técnicas adecuadas para controlar los riesgos a través de las redes abiertas. %{company}, no se hace responsable de los posibles perjuicios derivados de, a título enunciativo y no limitativo:',
                    4: 'Inferencias, omisiones, interrupciones, virus informáticos, averías y/o desconexiones en el funcionamiento operativo del sistema o en los aparatos o equipos de los usuarios, que impidan o retrasen la prestación de los servicios o navegación por el sistema;',
                    5: 'Retrasos o bloqueos en el uso causados por deficiencias o sobrecargas de internet o en otros sistemas electrónicos;',
                    6: 'Que puedan ser causados por terceras personas mediante intromisiones ilegítimas;',
                    7: 'Imposibilidad de prestar el servicio o permitir el acceso por causas no imputables a %{company}, debidas al usuario, a terceros o a supuestos de fuerza mayor;',
                    8: 'la privacidad y seguridad en el uso de la página web por parte del usuario y/o el acceso no consentido de terceros no autorizados.',
                },
                disputes: {
                    1: 'La prestación del servicio se rige por la legislación española, siendo competentes los Juzgados y Tribunales de %{province} a los que el Usuario se somete expresamente.',
                },
            },
            title: {
                legal: 'Información Legal',
                conditions: 'Condiciones de acceso y uso del sitio web',
                liability: 'Obligaciones del cliente/usuario turístico',
                rights: 'Derechos de propiedad industrial e intelectual',
                disclaimer: 'Exclusión de responsabilidad',
                disputes: 'Legislación y fuero',
            },
        },
    },
}

export default es
