import Cookies from './Cookies'
import { CookieProvider } from './types'
import { createContext } from 'react'

export const CookieContext = createContext<CookieProvider>({
    cookies: new Cookies(),
    consent: undefined,
    accept: () => {},
    decline: () => {},
})
