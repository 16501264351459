import PropTypes from 'prop-types'
import React from 'react'
import { ProductConfig, useTranslate } from '../../service'
import { Typography } from '@mui/material'

export interface ProductTitleProps {
    product: ProductConfig
}

export const ProductTitle = (props: ProductTitleProps) => {
    const { product } = props
    const { id, route } = product

    const translate = useTranslate()

    return (
        <>
            <Typography
                color='text.secondary'
                component='p'
                variant='overline'
                sx={{ marginTop: '16px' }}
            >
                {translate(route ? 'product.details.route' : 'product.details.freeRide')}
            </Typography>
            <Typography
                component='h1'
                variant='h1'
                sx={{ marginTop: 0 }}
            >
                {translate(`product.name.${id}`)}
            </Typography>
        </>
    )
}

ProductTitle.propTypes = {
    product: PropTypes.object,
}
