import React from 'react'
import { createTheme } from '@mui/material'

declare module '@mui/material/styles' {
    interface TypographyVariants {
        highlight1: React.CSSProperties
        highlight2: React.CSSProperties
    }

    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        highlight1?: React.CSSProperties
        highlight2?: React.CSSProperties
    }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        highlight1: true
        highlight2: true
    }
}

export const theme = createTheme({
    typography: {
        fontFamily: '\'Inter\', sans-serif',
        h1: {
            color: 'rgb(34,34,34)',
            fontSize: '26px',
            fontWeight: 600,
            lineHeight: '30px',
            margin: '24px 0 8px',
        },
        h2: {
            color: 'rgb(34,34,34)',
            fontSize: '22px',
            fontWeight: 600,
            lineHeight: '26px',
            margin: '0 0 24px',
        },
        h3: {
            color: 'rgb(34,34,34)',
            fontSize: '16px',
            fontWeight: 600,
            marginTop: '3px',
        },
        overline: {
            fontFamily: '\'Inter\', sans-serif',
            fontSize: '0.75rem',
            fontWeight: 600,
            textTransform: 'uppercase',
        },
        highlight1: {
            color: 'rgb(34,34,34)',
            fontFamily: '\'Inter\', sans-serif',
            fontSize: '16px',
            fontWeight: 600,
            marginTop: '3px',
        },
        highlight2: {
            color: 'rgb(34,34,34)',
            fontFamily: '\'Inter\', sans-serif',
            fontSize: '14px',
            fontWeight: 600,
            marginTop: '3px',
        },
        body1: {
            color: 'rgb(34,34,34)',
            fontSize: '16px',
            fontWeight: 400,
            marginBottom: '8px',
        },
        body2: {
            color: 'rgb(113,113,113)',
            fontSize: '14px',
            fontWeight: 400,
            marginBottom: '8px',
        },
    },
})
