import PhoneIcon from '@mui/icons-material/Phone'
import React from 'react'
import { Container, Divider, Grid, IconButton, Typography, styled, Button } from '@mui/material'
import {
    CHANGE_LOCALE,
    CONTACT_BY_PHONE,
    useConfig,
    useLocale,
    useLogEvent,
    useSetLocale,
    useTranslate
} from '../service'

const PREFIX = 'OrBanner'

const classes = {
    root: `${PREFIX}-root`,
    motto: `${PREFIX}-motto`,
    text: `${PREFIX}-text`,
    locale: `${PREFIX}-locale`,
    divider: `${PREFIX}-divider`,
}

const StyledBanner = styled('div')(({ theme }) => ({
    [`&.${classes.root}`]: {
        marginTop: theme.spacing(4),
    },
    [`& .${classes.motto}`]: {
        fontSize: '16px',
        fontWeight: 600,
        marginTop: theme.spacing(1),
        textTransform: 'none',
    },
    [`& .${classes.motto} span`]: {
        marginLeft: theme.spacing(2),
    },
    [`& .${classes.text}`]: {
        fontSize: '16px',
        fontWeight: 400,
    },
    [`& .${classes.locale}`]: {
        height: theme.spacing(6),
        width: theme.spacing(6),
    },
    [`& .${classes.divider}`]: {
        margin: theme.spacing(2, 0, 0),
    },
}))

export const Banner = () => {
    const locale = useLocale()
    const logEvent = useLogEvent()
    const translate = useTranslate()
    const setLocale = useSetLocale()
    const { contact } = useConfig()
    const { phone } = contact
    const tel = `tel:${phone.replace(/\D/g, '')}`

    const handleLocale = (newLocale: string) =>
        (): void => {
            setLocale(newLocale)
            logEvent(CHANGE_LOCALE, {
                from: locale,
                to: newLocale,
            })
        }

    const handleContactByPhone = (): void => {
        logEvent(CONTACT_BY_PHONE, {
            where: 'banner',
        })
    }

    return (
        <StyledBanner className={classes.root}>
            <Container maxWidth='md'>
                <Grid container spacing={0}>
                    <Grid item md={6} xs={12}>
                        <Typography
                            className={classes.motto}
                            component='h1'
                        >
                            {translate('brand.motto')}
                        </Typography>
                        <Typography
                            className={classes.text}
                            component='p'
                        >
                            {translate('brand.schedule')}
                            <br/>
                            {translate('brand.workdays')}
                        </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <div>
                            <IconButton onClick={handleLocale('es')}>
                                <img
                                    alt={translate('locale.es')}
                                    className={classes.locale}
                                    src='/asset/icon_flag_es.webp'
                                    title={translate('locale.es')}
                                />
                            </IconButton>
                            <IconButton onClick={handleLocale('en')}>
                                <img
                                    alt={translate('locale.en')}
                                    className={classes.locale}
                                    src='/asset/icon_flag_en.webp'
                                    title={translate('locale.en')}
                                />
                            </IconButton>
                            <IconButton /*onClick={handleLocale('it')}*/>
                                <img
                                    alt={translate('locale.it')}
                                    className={classes.locale}
                                    src='/asset/icon_flag_it.webp'
                                    title={translate('locale.it')}
                                />
                            </IconButton>
                        </div>
                        <Button
                            className={classes.motto}
                            color='primary'
                            href={tel}
                            onClick={handleContactByPhone}
                            variant='contained'
                        >
                            <PhoneIcon />
                            <span>
                                {translate('app.links.contactUs')}
                            </span>
                        </Button>
                    </Grid>
                </Grid>
                <Divider className={classes.divider} />
            </Container>
        </StyledBanner>
    )
}
